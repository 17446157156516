import React from "react";
import "./style.scss";

function Footer() {
  return (
    <>
      {/* <div className="footer_wrapper">
        <div className="container">
          <div className="row footer_top">
            <div className="col-md-4">
              <h3>Disclaimer</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco
              </p>
            </div>
            <div className="col-md-5">
              <h3>About Alamo Equity</h3>
              <div className="row">
                <div className="col-md-6">
                  <ul>
                    <li>
                      <a href="">About Us</a>
                    </li>
                    <li>
                      <a href="">Blog</a>
                    </li>
                    <li>
                      <a href="">FAQ's</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      <a href="">Terms and Conditoins</a>
                    </li>
                    <li>
                      <a href="">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <h3>Support</h3>
              <ul>
                <li>
                  <a href="">Contact Us</a>
                </li>
                <li>
                  <a href="">Help Center</a>
                </li>
                <li>
                  <a href="">info@domain.com</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row footer_bootom">
            <div className="col-md-12 text-center">
              <div className="copyright">
                <p>Copyright © 2022 Alamo Equity. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Footer;

import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Logo from "../../images/logo.png";
import Author from "../../images/profile.jpg";
import {
  FaBell,
  FaCog,
  FaHeart,
  FaSignOutAlt,
  FaUser,
  FaUserCog,
  FaWallet,
} from "react-icons/fa";
import "./style.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Header({ page }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  return (
    <>
      <div className="header_wrapper">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-5">
              <div className="logo">
                <img
                  src={Logo}
                  alt=""
                  width={130}
                  onClick={() => navigate("/")}
                />
              </div>
            </div>

            <div className="col-7  d-flex align-items-center justify-content-end">
              {!user.login ? (
                <div className="head_menu">
                  {page !== "signin" && (
                    <a className="nav-l7g6bonm9['0nl ink" href="/signin">
                      Sign In{" "}
                    </a>
                  )}

                  {page !== "signup" && (
                    <a className="nav-link" href="/signup">
                      Sign Up{" "}
                    </a>
                  )}
                </div>
              ) : (
                <Dropdown className="acc_dropdown">
                  <Dropdown.Toggle
                    variant="success"
                    id="profile_menu"
                    className="act_head"
                  >
                    <span className="act_img">
                      <img src={Author} alt="Author" width={80} />
                    </span>
                    <span className="profile_meta">
                      <span className="act_fname">{user.data.name}</span>
                      {/* <span className="act_cost">865 $</span> */}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#/action-1"
                      onClick={() => window.open("/profile")}
                    >
                      <FaUser /> Profile
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      href="#/action-1"
                      onClick={() => window.open("/investing-account")}
                    >
                      <FaUserCog /> Investing Accounts
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      {" "}
                      <FaWallet />
                      Wallet
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      {" "}
                      <FaHeart />
                      Favourite Items
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      {" "}
                      <FaCog /> Settings
                    </Dropdown.Item> */}
                    <Dropdown.Item href="/logout">
                      {" "}
                      <FaSignOutAlt /> Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;

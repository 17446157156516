import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Fade from "react-reveal/Fade";
import Tabs from "react-bootstrap/Tabs";
import Carousel from "react-bootstrap/Carousel";
import { FaCheckCircle } from "react-icons/fa";
import "./style.scss";
//import AMGCover from "../../images/amg_cover.jpg";
import AMGCover from "../../images/Alamo XS Corporate Brochure.jpg";

import cc_2 from "../../images/past-project/CC_2.jpg";
import cc_1 from "../../images/past-project/CC_1.jpg";
import cc_3 from "../../images/past-project/CC_3.jpg";
import cc_4 from "../../images/past-project/CC_4.jpg";
import cc_5 from "../../images/past-project/CC_6.jpg";

import helios_1 from "../../images/past-project/helios_1.jpg";
import helios_2 from "../../images/past-project/helios_2.jpg";
import helios_3 from "../../images/past-project/helios_3.jpg";
import helios_4 from "../../images/past-project/helios_4.jpg";

import La_Celleste_1 from "../../images/past-project/La_Celleste_1.jpg";
import La_Celleste_2 from "../../images/past-project/La_Celeste_4.jpg";

import bali_1 from "../../images/past-project/Villa_Bali_Gallery_11.jpg";
import bali_2 from "../../images/past-project/Villa_Bali_Gallery_15.jpg";
import bali_3 from "../../images/past-project/Villa_Bali_Gallery_16.jpg";
import bali_4 from "../../images/past-project/Villa_Bali_Gallery_20.jpg";

import Patio_1 from "../../images/past-project/Patio.jpg";
import { Modal, ModalHeader, ModalBody } from "react-bootstrap";
import {
  numberFormatter,
  numberFormatterTrackRecord,
  numberCommaTableFormatter,
} from "../../utils/common";
import { useSelector } from "react-redux";

const People = ({ leaderRef, groupRef, trackRef }) => {
  const [modal, setModal] = useState({
    show: false,
    index: 0,
    title: "",
    category: "past",
    images: [],
  });
  const user_redux = useSelector((state) => state.user);

  let usd_value = 82;

  const info = [
    {
      src: "https://ik.imagekit.io/alamogroup/dev/india%20fund/tr-2-min.png",
      title: "La Celeste - French style apartments - Porur, Chennai - 2008",
    },
    {
      src: "https://ik.imagekit.io/alamogroup/dev/india%20fund/tr-1-min.png",
      title: "Siena - Tuscan Styled apartments - Padur,Chennai - 2012",
    },
    {
      src: "https://ik.imagekit.io/alamogroup/dev/india%20fund/tr-4-min.png",
      title:
        "Catalunya City - Spanish style apartments - Siruseri, Chennai - 2015",
    },
    {
      src: "https://ik.imagekit.io/alamogroup/dev/india%20fund/tr-3-min.png",
      title: "Patio - Low rise apartments - Neelambur, Coimbatore - 2022",
    },
  ];

  const past_projects = [
    {
      title: "Catalunya City",
      images: [
        // {
        //   src: cc_1,
        //   title: "Catalunya City",
        // },
        {
          src: cc_2,
          title: "Catalunya City",
        },
        {
          src: cc_3,
          title: "Catalunya City",
        },
        {
          src: cc_4,
          title: "Catalunya City",
        },
        {
          src: cc_5,
          title: "Catalunya City",
        },
      ],
    },
    {
      title: "Helios City",
      images: [
        {
          src: helios_1,
          title: "Helios City",
        },
        {
          src: helios_2,
          title: "Helios City",
        },
        {
          src: helios_3,
          title: "Helios City",
        },
        {
          src: helios_4,
          title: "Helios City",
        },
      ],
    },
    {
      title: "La Celeste",
      images: [
        {
          src: La_Celleste_1,
          title: "La Celeste",
        },
        {
          src: La_Celleste_2,
          title: "La Celeste",
        },
      ],
    },
    {
      title: "Villa Bali",
      images: [
        {
          src: bali_1,
          title: "Villa Bali",
        },
        {
          src: bali_2,
          title: "Villa Bali",
        },
        {
          src: bali_3,
          title: "Villa Bali",
        },
        {
          src: bali_4,
          title: "Villa Bali",
        },
      ],
    },
    {
      title: "Patio",
      images: [
        {
          src: Patio_1,
          title: "Patio",
        },
      ],
    },
  ];

  const trackRecord = [
    {
      "S.No": "1",
      "Project Name": "Casa XS",
      Year: 1997,
      Type: "Apartments",
      "Market, City": "Manapakam, Chennai",
      Units: "96",
      "Development Area": "142000",
      "Total Revenue": 280000000,
      "Total Equity Invested": 30000000,
      "Realised Returns": 70000000,
      "Realized IRR %": "25%",
      "Realized Equity Multiple": "2.60",
      "Project period": "6.0",
    },
    {
      "S.No": "2",
      "Project Name": "Villa Espana",
      Year: 2003,
      Type: "Apartments",
      "Market, City": "Velachery, Chennai",
      Units: "45",
      "Development Area": "70000",
      "Total Revenue": 150000000,
      "Total Equity Invested": 30000000,
      "Realised Returns": 60000000,
      "Realized IRR %": "22%",
      "Realized Equity Multiple": 1.83,
      "Project period": "3.0",
    },
    {
      "S.No": "3",
      "Project Name": "Prathyeka",
      Year: 2004,
      Type: "Apartments",
      "Market, City": "Mandaiveli, Chennai",
      Units: "12",
      "Development Area": "14000",
      "Total Revenue": 70000000,
      "Total Equity Invested": 20000000,
      "Realised Returns": 20000000,
      "Realized IRR %": "25%",
      "Realized Equity Multiple": 1.65,
      "Project period": "2.0",
    },
    {
      "S.No": "4",
      "Project Name": "Chesterfields",
      Year: 2005,
      Type: "Luxury Villas",
      "Market, City": "Ramapuram, Chennai",
      Units: "21",
      "Development Area": "44000",
      "Total Revenue": 150000000,
      "Total Equity Invested": 40000000,
      "Realised Returns": 70000000,
      "Realized IRR %": "33%",
      "Realized Equity Multiple": 1.77,
      "Project period": "2.0",
    },
    {
      "S.No": "5",
      "Project Name": "Pallava Heights",
      Year: 2005,
      Type: "Apartments",
      "Market, City": "Mylapore, Chennai",
      Units: "20",
      "Development Area": "44000",
      "Total Revenue": 150000000,
      "Total Equity Invested": 50000000,
      "Realised Returns": 80000000,
      "Realized IRR %": "30%",
      "Realized Equity Multiple": 1.62,
      "Project period": "2.0",
    },
    {
      "S.No": "6",
      "Project Name": "Futura",
      Year: 2005,
      Type: "Commercial",
      "Market, City": "Sholinganallur, Chennai",
      Units: "7",
      "Development Area": "568000",
      "Total Revenue": 2000000000,
      "Total Equity Invested": 220000000,
      "Realised Returns": 560000000,
      "Realized IRR %": "16%",
      "Realized Equity Multiple": 2.54,
      "Project period": "5.0",
    },
    {
      "S.No": "7",
      "Project Name": "La Celeste",
      Year: 2008,
      Type: "Apartments",
      "Market, City": "Porur, Chennai",
      Units: "426",
      "Development Area": "734000",
      "Total Revenue": 2500000000,
      "Total Equity Invested": 280000000,
      "Realised Returns": 930000000,
      "Realized IRR %": "34%",
      "Realized Equity Multiple": 3.32,
      "Project period": "6.0",
    },
    {
      "S.No": "8",
      "Project Name": "Fontana",
      Year: 2008,
      Type: "Apartments",
      "Market, City": "Padur, Chennai",
      Units: "80",
      "Development Area": "130000",
      "Total Revenue": 300000000,
      "Total Equity Invested": 60000000,
      "Realised Returns": 110000000,
      "Realized IRR %": "22%",
      "Realized Equity Multiple": 1.78,
      "Project period": "3.0",
    },
    {
      "S.No": "9",
      "Project Name": "Amity",
      Year: 2008,
      Type: "Apartments",
      "Market, City": "Padur, Chennai",
      Units: "216",
      "Development Area": "228000",
      "Total Revenue": 572500000,
      "Total Equity Invested": 100000000,
      "Realised Returns": 179500000,
      "Realized IRR %": "26%",
      "Realized Equity Multiple": 1.85,
      "Project period": "3.0",
    },
    {
      "S.No": "10",
      "Project Name": "Maxworth",
      Year: 2009,
      Type: "Apartments",
      "Market, City": "Maxworth, Chennai",
      Units: "32",
      "Development Area": "40000",
      "Total Revenue": 150000000,
      "Total Equity Invested": 30000000,
      "Realised Returns": 60000000,
      "Realized IRR %": "42%",
      "Realized Equity Multiple": 2.26,
      "Project period": "1.5",
    },
    {
      "S.No": "11",
      "Project Name": "Kovilambakkam",
      Year: 2009,
      Type: "Apartments",
      "Market, City": "Kovilambakkam, Chennai",
      Units: "32",
      "Development Area": "30656",
      "Total Revenue": 100000000,
      "Total Equity Invested": 30000000,
      "Realised Returns": 80000000,
      "Realized IRR %": "28%",
      "Realized Equity Multiple": 1.56,
      "Project period": "2.0",
    },
    {
      "S.No": "12",
      "Project Name": "Porur Garden",
      Year: 2010,
      Type: "Apartments",
      "Market, City": "Porur Garden, Chennai",
      Units: "42",
      "Development Area": "42000",
      "Total Revenue": 150000000,
      "Total Equity Invested": 40000000,
      "Realised Returns": 70000000,
      "Realized IRR %": "26%",
      "Realized Equity Multiple": 1.73,
      "Project period": "2.0",
    },
    {
      "S.No": "13",
      "Project Name": "Madipakkam",
      Year: 2011,
      Type: "Apartments",
      "Market, City": "Madipakkam, Chennai",
      Units: "8",
      "Development Area": "8540",
      "Total Revenue": 30000000,
      "Total Equity Invested": 20000000,
      "Realised Returns": 30000000,
      "Realized IRR %": "32%",
      "Realized Equity Multiple": 1.31,
      "Project period": "1.5",
    },
    {
      "S.No": "14",
      "Project Name": "Villa Bali",
      Year: 2011,
      Type: "Luxury Villas",
      "Market, City": "Porur, Chennai",
      Units: "18",
      "Development Area": "30000",
      "Total Revenue": 200000000,
      "Total Equity Invested": 50000000,
      "Realised Returns": 80000000,
      "Realized IRR %": "45%",
      "Realized Equity Multiple": 1.67,
      "Project period": "1.5",
    },
    {
      "S.No": "15",
      "Project Name": "Harmony",
      Year: 2012,
      Type: "Apartments",
      "Market, City": "Padur, Chennai",
      Units: "228",
      "Development Area": "228000",
      "Total Revenue": 800000000,
      "Total Equity Invested": 200000000,
      "Realised Returns": 350000000,
      "Realized IRR %": "30%",
      "Realized Equity Multiple": 1.75,
      "Project period": "1.5",
    },
    {
      "S.No": "16",
      "Project Name": "Siena",
      Year: 2012,
      Type: "Apartments",
      "Market, City": "Padur, Chennai",
      Units: "140",
      "Development Area": "240000",
      "Total Revenue": 1000000000,
      "Total Equity Invested": 200000000,
      "Realised Returns": 370000000,
      "Realized IRR %": "23%",
      "Realized Equity Multiple": 1.85,
      "Project period": "2.5",
    },
    {
      "S.No": "17",
      "Project Name": "Green Edge",
      Year: 2013,
      Type: "Apartments",
      "Market, City": "Rayapattah, Chennai",
      Units: "11",
      "Development Area": "19000",
      "Total Revenue": 300000000,
      "Total Equity Invested": 60000000,
      "Realised Returns": 120000000,
      "Realized IRR %": "50%",
      "Realized Equity Multiple": 1.97,
      "Project period": "1.5",
    },
    {
      "S.No": "18",
      "Project Name": "Symphony",
      Year: 2013,
      Type: "Apartments",
      "Market, City": "Padur, Chennai",
      Units: "228",
      "Development Area": "228000",
      "Total Revenue": 800000000,
      "Total Equity Invested": 150000000,
      "Realised Returns": 290000000,
      "Realized IRR %": "23%",
      "Realized Equity Multiple": 1.96,
      "Project period": "2.5",
    },
    {
      "S.No": "19",
      "Project Name": "Vivacity",
      Year: 2014,
      Type: "Apartments",
      "Market, City": "Guduvancherri, Chennai",
      Units: "96",
      "Development Area": "90696",
      "Total Revenue": 250000000,
      "Total Equity Invested": 40000000,
      "Realised Returns": 80000000,
      "Realized IRR %": "13%",
      "Realized Equity Multiple": 2.08,
      "Project period": "4.0",
    },
    {
      "S.No": "20",
      "Project Name": "Catalunya City",
      Year: 2015,
      Type: "Apartments",
      "Market, City": "Siruseri, Chennai",
      Units: "114",
      "Development Area": "135000",
      "Total Revenue": 450000000,
      "Total Equity Invested": 100000000,
      "Realised Returns": 190000000,
      "Realized IRR %": "13%",
      "Realized Equity Multiple": 1.87,
      "Project period": "3.0",
    },
    {
      "S.No": "21",
      "Project Name": "CBE - Courtyard",
      Year: 2016,
      Type: "Apartments",
      "Market, City": "Neelambur, Coimbatore",
      Units: "108",
      "Development Area": "76086",
      "Total Revenue": 310000000,
      "Total Equity Invested": 100000000,
      "Realised Returns": 160000000,
      "Realized IRR %": "23%",
      "Realized Equity Multiple": 1.55,
      "Project period": "2.0",
    },
    {
      "S.No": "22",
      "Project Name": "Centra",
      Year: 2017,
      Type: "Apartments",
      "Market, City": "Perungalathur, Chennai",
      Units: "65",
      "Development Area": "75431",
      "Total Revenue": 350000000,
      "Total Equity Invested": 100000000,
      "Realised Returns": 170000000,
      "Realized IRR %": "23%",
      "Realized Equity Multiple": 1.65,
      "Project period": "2.0",
    },
    {
      "S.No": "23",
      "Project Name": "CBE - Courtyard P2A",
      Year: 2017,
      Type: "Apartments",
      "Market, City": "Neelambur, Coimbatore",
      Units: "124",
      "Development Area": "95956",
      "Total Revenue": 410500000,
      "Total Equity Invested": 60000000,
      "Realised Returns": 130000000,
      "Realized IRR %": "15%",
      "Realized Equity Multiple": 2.17,
      "Project period": "4.0",
    },
  ];

  const ourProject = [
    {
      "S.No": "1",
      "Project Name": "Enveedu*",
      Year: 2022,
      Type: "Apartments",
      "Market, City": "Guduvanchery, Chennai",
      Units: "449",
      "Development Area": "231102",
      "Total Revenue": 730000000,
      "Total Equity Invested": 80000000,
      "Expected Returns": "126000000",
      Expected: "12%",
      "Expected Equity Multiple": 1.58,
      "Project period": 5,
    },
    {
      "S.No": "2",
      "Project Name": "Courtyard P2B*",
      Year: 2023,
      Type: "Apartments",
      "Market, City": "Neelambur, Coimbatore",
      Units: "124",
      "Development Area": "88844",
      "Total Revenue": 420000000,
      "Total Equity Invested": 45000000,
      "Expected Returns": "80000000",
      Expected: "18%",
      "Expected Equity Multiple": 1.78,
      "Project period": 5,
    },
    {
      "S.No": "3",
      "Project Name": "Catalunya City Ph IIA*",
      Year: 2023,
      Type: "Apartments",
      "Market, City": "Siruseri, Chennai",
      Units: "124",
      "Development Area": "148484",
      "Total Revenue": 650000000,
      "Total Equity Invested": 100000000,
      "Expected Returns": "180000000",
      Expected: "18%",
      "Expected Equity Multiple": "1.80",
      "Project period": 5,
    },
    {
      "S.No": "4",
      "Project Name": "Magnus*",
      Year: 2023,
      Type: "Apartments",
      "Market, City": "Alwarthurunagar, Chennai",
      Units: "8",
      "Development Area": "8631",
      "Total Revenue": 100000000,
      "Total Equity Invested": 20000000,
      "Expected Returns": "26500000",
      Expected: "16%",
      "Expected Equity Multiple": 1.51,
      "Project period": 2,
    },
    {
      "S.No": "5",
      "Project Name": "Patio*",
      Year: 2024,
      Type: "Apartments",
      "Market, City": "Neelambur, Coimbatore",
      Units: "155",
      "Development Area": "124250",
      "Total Revenue": 620000000,
      "Total Equity Invested": 60000000,
      "Expected Returns": "120000000",
      Expected: "25%",
      "Expected Equity Multiple": "2.00",
      "Project period": 3,
    },
    {
      "S.No": "6",
      "Project Name": "Castillo **",
      Year: 2023,
      Type: "Plotted Development",
      "Market, City": "Siruseri, Chennai",
      Units: "105",
      "Development Area": "117562",
      "Total Revenue": 470000000,
      "Total Equity Invested": 200000000,
      "Expected Returns": "350000000",
      Expected: "30%",
      "Expected Equity Multiple": 1.75,
      "Project period": 2,
    },
    {
      "S.No": "7",
      "Project Name": "Catalunya City - Ph IV **",
      Year: 2026,
      Type: "Apartments",
      "Market, City": "Siruseri, Chennai",
      Units: "600",
      "Development Area": "600000",
      "Total Revenue": 4390000000,
      "Total Equity Invested": 223000000,
      "Expected Returns": "447000000",
      Expected: "22%",
      "Expected Equity Multiple": "2.00",
      "Project period": 5,
    },
    {
      "S.No": "8",
      "Project Name": "Calisto **",
      Year: 2026,
      Type: "Apartments",
      "Market, City": "MWC,Chennai",
      Units: "685",
      "Development Area": "580000",
      "Total Revenue": 3000000000,
      "Total Equity Invested": 360000000,
      "Expected Returns": "595000000",
      Expected: "21%",
      "Expected Equity Multiple": 1.64,
      "Project period": 5,
    },
    {
      "S.No": "9",
      "Project Name": "Interlace**",
      Year: 2025,
      Type: "Apartments",
      "Market, City": "Porur, Chennai",
      Units: "12",
      "Development Area": "30000",
      "Total Revenue": 300000000,
      "Total Equity Invested": 100000000,
      "Expected Returns": "170000000",
      Expected: "21%",
      "Expected Equity Multiple": "1.70",
      "Project period": 3,
    },
    {
      "S.No": "10",
      "Project Name": "Lush Gardens**",
      Year: 2024,
      Type: "Plotted Development",
      "Market, City": "Padur, Chennai",
      Units: "10",
      "Development Area": "17424",
      "Total Revenue": 60000000,
      "Total Equity Invested": 40000000,
      "Expected Returns": "62000000",
      Expected: "25%",
      "Expected Equity Multiple": 1.55,
      "Project period": 2,
    },
  ];

  return (
    <>
      <div className="sp_sec mb-4" ref={groupRef}>
        {/* <Fade bottom> */}
        <h4>
          <span className="fw-bolder text-app-primary"> The Alamo Group</span>{" "}
          <span className="fw-normal text-app-primary"> (Sponsor)</span>
        </h4>
        {/* </Fade> */}
        <hr />
        {/* <Fade bottom> */}
        <p className="mb-0 text-justify">
          Headquartered in San Antonio with offices in Houston and Dallas, the
          Alamo Group consists of the Sponsor, the Developer, the General
          Partner, and the Investment Manager. The Alamo Group is founded and
          led by Mr. Logan Anjaneyulu, a successful real estate investor with
          over twenty years of real estate investment and development
          experience.
          <br />
          <br />
          The Developer, Alamo XS Real (AXS), is a seasoned real estate
          development firm, is a seasoned real estate development firm
          consisting of a strong suite of development professionals with
          extensive experience in all facets of residential township
          development. Alamo XS Real has over 28 Years’ experience of building
          high quality residential townships and has delivered over ~ 3.3 Mn.sft
          and ~ 2000+ residential units across Chennai and Coimbatore in India.
          <br />
          <br />
          Alamo XS Real is know for its attention to design and is poised to
          bring world architecture and lifestyle to its customers in India
          through its design and space planning of its residential townships.
          Alamo XS Real is the only developer in India to have developed
          residential townships in 9 different thematic architectures that are
          inspired from across the world. AXS is a vertically integrated
          development company with its primary focus on development of low and
          high rise residential communities in Chennai and Coimbatore with a
          vision to expand to all other South India Metro cities in the next 5
          years. AXS's development process includes all phases such as site
          selection, site planning, market needs analysis, pre-design,
          architectural, construction, sales & marketing , and final exit.
          <br />
          <br />
          AXS's current active residential pipeline includes development of over
          ~3,850 units at various stages of development, with an estimated ~590
          units under construction in 2023 with planned certificate of occupancy
          (CO) on or by June 2023; and ~3,250 units planned for construction
          start in 2023 and 2024 with deliveries through 2026 and 2027.
        </p>
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4">
        {/* <Fade bottom> */}
        <div className="cover_image">
          <img src={AMGCover} alt="AMG" />
        </div>
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4" ref={leaderRef}>
        {/* <Fade bottom> */}
        <h4 className="fw-bolder text-app-primary">Leadership Team</h4>
        {/* </Fade> */}
        <hr />
        {/* <Fade bottom> */}
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Logan-Anjaneyulu_nh8nou.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Logan Anjaneyulu <span>Promoter - Alamo XS Real</span>
                </h3>
                <p className="text-justify">
                  Logan Anjaneyulu founded Alamo Equity, an Alamo Group Company
                  in 2017, and AMG shortly thereafter. AMG is a multifamily
                  development company, and its team members have combined, over
                  150 years of multifamily development experience. Logan
                  Anjaneyulu has over 20 years of real estate investing
                  experience and his expertise include syndication, development,
                  and management of various real estate projects. Logan has over
                  20 years of corporate, construction, energy, commercial real
                  estate experience, with many successful exits. At the Alamo
                  Group, Logan is responsible for strategy, capital raising,
                  investment screening, and execution. Logan holds a Civil
                  Engineering undergraduate degree from College of Engineering
                  Guindy at Anna University, Chennai, India; a Master of Science
                  (M.S) in Construction Management from Michigan State
                  University, East Lansing, Michigan; and an MBA from Kellogg
                  School of Management at Northwestern University, Evanston,
                  Illinois. As Founder and President, Logan provides mentorship
                  and oversight to the Executive Leadership team at Alamo Group
                  Companies (AGC) globally.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/prajnesh.png?updatedAt=1719617065922"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Prajnesh Gunneswaran <span>Promoter - Alamo XS Real</span>
                </h3>
                <p className="text-justify">
                  Prajnesh Gunneswaran is the son of Late. Mr. S. G Prabhakaran
                  - the Founder of XS Real. Prajnesh is a the highest-ranked
                  Indian singles Tennis player who represents India at the Davis
                  Cup. He has won 2 ATP Challenger and 8 ITF titles and 1 ITF
                  title in doubles. At the 2018 Asia Games in Jakarta, he won
                  the bronze medal in men’s singles event. Post Mr. S.G.
                  Prabakaran’s demise, Prajnesh with a vision to expand the foot
                  print of XS Real across the globe has invited Alamo Group from
                  Texas, USA to invest and build the future together to form
                  Alamo XS Real.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  style={{ background: "#fff" }}
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/pete%20alford.png?updatedAt=1719609492276"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Pete Alford <span>Managing Director, Capital Markets</span>
                </h3>
                <p className="text-justify">
                  Pete has over 35 years of experience in fundraising, capital
                  markets structuring, sales/marketing, investment management
                  consulting, business development, and alternative strategies,
                  working in Toronto, New York, London (UK), Denver, Chicago,
                  Phoenix, and Los Angeles. Pete has held positions as Managing
                  Director and Senior Vice President at some of the world’s
                  global financial institutions such as JPMorgan Private Bank,
                  Morgan Stanley, and RBC/City National Bank. He has focused on
                  real estate, private equity and alternative investment
                  management for high net worth, family office and institutional
                  clients. Pete spearheads fundraising and capital needs
                  planning with a focus on the creation, implementation, and
                  execution of multiple fundraising rounds. He leads the
                  Investor Relations and Capital Markets team that focuses on
                  discussions and engagement with HNW/UHNW Investors, Family
                  Offices, Venture Capital Funds, Private Equity Institutions
                  and Pension/Insurance Funds. Community and industry
                  involvement includes Board Member, Care Fund (Phoenix) as well
                  as a member of NMHC and ULI nationally. Pete holds an Honors
                  BA in business administration from Western University (London,
                  Ontario) and an MBA/Finance from the University of Denver,
                  Daniels School of Management.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </Fade> */}
        {/* <Fade bottom> */}
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/raj.png?updatedAt=1719617065928"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Raj Kumar B <span>Chairman - Alamo XS Real</span>
                </h3>
                <p className="text-justify">
                  With 30+ years of experience in construction management and
                  manufacturing high-strength concrete products, Raj Kumar has
                  been an entrepreneur by choice. He is also successfully
                  managing a construction company called 'Concreators'. Raj
                  Kumar's interests go beyond real estate and also cover
                  high-end manufacturing for related category products. His
                  manufacturing unit makes High-strength Concrete Pavers,
                  Concrete Blocks, Fly AshBricks, etc. under the name of Adhitya
                  Precast Products.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  style={{ background: "#fff" }}
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/kanan.png?updatedAt=1719617065920"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Kannan Mahalingam <span>President & CEO - Alamo XS Real</span>
                </h3>
                <p className="text-justify">
                  A civil engineering graduate from a premier engineering
                  school, College of Engineering Guindy, Kannan went on to do
                  his Masters in Construction Management from the same
                  institution. Post that Kannan also pursued a management degree
                  in General Management from the renowned Anna University.
                  Having worked in India and abroad, Kannan has over 20 years of
                  experience in various leading organisations like CCCL, Exceed,
                  DLF, MARG, Costrack (in USA), Eversendai & TVS Emerald. Most
                  of these assignments have been in senior managerial positions.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* </Fade> */}
        {/* <Fade bottom> */}
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Mili-Patel-1_dbyhwa.jpg?updatedAt=1719609492526"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Mili Patel <span>VP and General Counsel</span>
                </h3>
                <p className="text-justify">
                  Mili Patel joined Alamo Equity as its Vice President and
                  General Counsel in July of 2020. She has been practicing law
                  in the state of Texas for 18 years with experience in
                  corporate transactions, real estate, labor law, construction
                  law, and litigation. She earned a Juris Doctorate from Texas
                  A&M School of Law and a Bachelor of Business Admin in
                  Economics from Baylor University. Mili is responsible for
                  overseeing all legal matters at the Alamo Group including real
                  estate acquisitions, dispositions, compliance, and general
                  counsel. In addition, Mili is responsible for supervising
                  outside counsel in Alamo Group’s various transactions and
                  other legal matters.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4">
        {/* <Fade bottom> */}
        <h4 className="fw-bolder  text-app-primary">Corporate Video</h4>
        {/* </Fade> */}
        <hr />
        <div className="cover_image">
          <iframe
            width="100%"
            height="515"
            src="https://www.youtube.com/embed/abwVUOA8870?rel=0"
            title="Alamo XS Real Corporate Video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4" ref={trackRef}>
        {/* <Fade bottom> */}
        <h4 className="fw-bolder  text-app-primary">Track Record</h4>
        {/* </Fade> */}
        <hr />
        <div className="row">
          <div className="col-md-12 text-center">
            {/* <Fade bottom> */}
            <div className="text-justify mb-4 help-text-track">
              Founded in 1995, Alamo XS Real is one of the renowned real estate
              developers in Chennai Residential Market. Alamo XS Real was a
              pioneer in innovative concepts such as Bungalow Apartments,
              Project Specific Thematic Architecture and Customer Specific
              Customization. Alamo XS Real is the only developer in India to
              have delivered projects in 9 different thematic architectures
              inspired from across the world. Over the last 28 Years XS Real has
              delivered over ~ 3.31 Mn.sft and 2000+ homes as shown below.
            </div>
            {/* </Fade> */}

            {/* <Fade bottom> */}
            <div className="table table-responsive mb-5 m-auto">
              <table className="table table-bordered mb-0 table-sm table-track-record">
                <thead>
                  <tr className="theme-bg-table">
                    <th>S.No</th>
                    <th className="text-start" style={{ width: "8rem" }}>
                      Project Name
                    </th>
                    <th style={{ width: "4rem" }}>Year</th>
                    <th style={{ width: "7rem" }}>Type</th>
                    <th style={{ width: "8.2rem" }}>
                      Location <br /> (India)
                    </th>
                    <th style={{ width: "5.2rem" }}>No of Units</th>
                    <th style={{ width: "7.2rem" }}>
                      Area <br />
                      (sft)
                    </th>
                    <th style={{ width: "5.5rem" }}>Total Revenue</th>
                    <th style={{ width: "6.5rem" }}>Total Equity Invested</th>
                    <th style={{ width: "6.5rem" }}>Realised Returns</th>

                    <th style={{ width: "5.2rem" }}>Realized IRR %</th>
                    <th style={{ width: "9.5rem" }}>
                      Realized Equity Multiple
                    </th>
                    <th style={{ width: "5.2rem" }}>
                      Duration <br /> (Years)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {trackRecord.map((obj, i) => (
                    <tr key={`ulist${i}`}>
                      <td>{obj["S.No"]}</td>
                      <td className="text-start">{obj["Project Name"]}</td>
                      <td>{obj.Year}</td>
                      <td className="text-start">{obj.Type}</td>
                      <td className="text-start">{obj["Market, City"]}</td>
                      <td> {obj.Units} </td>
                      <td>
                        {numberCommaTableFormatter(obj["Development Area"])}
                      </td>
                      <td className="text-start">
                        {numberFormatterTrackRecord(
                          obj["Total Revenue"],
                          user_redux.currency,
                          usd_value
                        )}{" "}
                      </td>
                      <td className="text-start">
                        {numberFormatterTrackRecord(
                          obj["Total Equity Invested"],
                          user_redux.currency,
                          usd_value
                        )}
                      </td>
                      <td className="text-start">
                        {numberFormatterTrackRecord(
                          obj["Realised Returns"],
                          user_redux.currency,
                          usd_value
                        )}
                      </td>

                      <td>{obj["Realized IRR %"]}</td>
                      <td>{obj["Realized Equity Multiple"]}</td>
                      <td>{obj["Project period"]}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="theme-bg-table">
                    <th colSpan={5}>Our Past Projects Completed </th>
                    <th>2,169</th>
                    <th>3.31 Million</th>
                    <th className="text-start">
                      {numberFormatter(
                        11490000000,
                        user_redux.currency,
                        usd_value
                      )}
                    </th>
                    <th className="text-start">
                      {numberFormatter(
                        2050000000,
                        user_redux.currency,
                        usd_value
                      )}
                    </th>
                    <th className="text-start">
                      {numberFormatter(
                        4260000000,
                        user_redux.currency,
                        usd_value
                      )}
                    </th>
                    <th>27%</th>
                    <th>1.93</th>
                    <th>2.8</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <h5 className="text-app-primary text-start">
              Our Current Projects
            </h5>
            <div className="table table-responsive mb-4 m-auto">
              <table className="table table-bordered mb-0 table-sm table-track-record">
                <thead>
                  <tr className="theme-bg-table">
                    <th>S.No</th>
                    <th className="text-start" style={{ width: "8.8rem" }}>
                      Project Name
                    </th>
                    <th style={{ width: "4rem" }}>Year</th>
                    <th style={{ width: "8rem" }}>Type</th>
                    <th style={{ width: "8.2rem" }}>
                      Location <br /> (India)
                    </th>
                    <th style={{ width: "5.2rem" }}>No of Units</th>
                    <th style={{ width: "12.2rem" }}>
                      Area <br />
                      (sft)
                    </th>
                    <th style={{ width: "6.5rem" }}>Total Revenue</th>
                    <th style={{ width: "6.5rem" }}>Total Equity Invested</th>
                    <th style={{ width: "6.5rem" }}>Expected Returns</th>
                    <th style={{ width: "5.2rem" }}>
                      Expected <br />
                      IRR %
                    </th>

                    <th style={{ width: "16.5rem" }}>
                      Expected Equity Multiple
                    </th>
                    <th style={{ width: "5.2rem" }}>
                      Duration <br /> (Years)
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {ourProject.map((obj, i) => (
                    <tr key={`uList${i}`}>
                      <td>{obj["S.No"]}</td>
                      <td className="text-start">{obj["Project Name"]}</td>
                      <td>{obj.Year}</td>
                      <td className="text-start">{obj.Type}</td>
                      <td className="text-start">{obj["Market, City"]}</td>
                      <td>{obj.Units} </td>
                      <td>
                        {" "}
                        {numberCommaTableFormatter(
                          obj["Development Area"]
                        )}{" "}
                      </td>
                      <td className="text-start">
                        {numberFormatterTrackRecord(
                          obj["Total Revenue"],
                          user_redux.currency,
                          usd_value
                        )}{" "}
                      </td>
                      <td className="text-start">
                        {numberFormatterTrackRecord(
                          obj["Total Equity Invested"],
                          user_redux.currency,
                          usd_value
                        )}
                      </td>
                      <td className="text-start">
                        {numberFormatterTrackRecord(
                          obj["Expected Returns"],
                          user_redux.currency,
                          usd_value
                        )}
                      </td>
                      <td>{obj.Expected}</td>
                      <td>{obj["Expected Equity Multiple"]}</td>
                      <td>{obj["Project period"]}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="theme-bg-table">
                    <th colSpan={5}>Our Current Projects Under Development</th>
                    <th>2,272</th>
                    <th>1.95 Million</th>
                    <th className="text-start">
                      {numberFormatter(
                        10740000000,
                        user_redux.currency,
                        usd_value
                      )}
                    </th>
                    <th className="text-start">
                      {numberFormatter(
                        1230000000,
                        user_redux.currency,
                        usd_value
                      )}
                    </th>
                    <th className="text-start">
                      {numberFormatter(
                        2170000000,
                        user_redux.currency,
                        usd_value
                      )}
                    </th>
                    <th>21%</th>
                    <th>1.73</th>
                    <th>4</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="text-justify help-text-track">
              <ul>
                <li>
                  <b className="app-color">Note 1* </b> Projects 1-5 above are
                  projects currently launched and are in different stages of
                  construction.
                </li>
                <li>
                  <b className="app-color">Note 2** </b> Projects 6-10 above are
                  projects currently either in feasibility or pre-development
                  phase with anticipated construction start in 2023 -24
                </li>
              </ul>
            </div>
            {/* </Fade> */}
          </div>

          {/* <Fade bottom> */}
          <div className="col-md-12">
            <div className="row ">
              <div className="col-md-6">
                <img
                  role="button"
                  src="https://ik.imagekit.io/alamogroup/dev/india%20fund/tr-2-min.png?updatedAt=1719619450223"
                  className="w-100 mb-1 custom-height-fund-img"
                  onClick={() =>
                    setModal({
                      show: true,
                      index: 0,
                      category: "past",
                      images: info,
                    })
                  }
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  {" "}
                  <span style={{ padding: "5px" }}>
                    La Celeste - Porur, Chennai - 2008
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  role={"button"}
                  src="https://ik.imagekit.io/alamogroup/dev/india%20fund/tr-1-min.png?updatedAt=1719619450081"
                  className="w-100 mb-1 custom-height-fund-img"
                  onClick={() =>
                    setModal({
                      show: true,
                      index: 1,
                      category: "past",
                      images: info,
                    })
                  }
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span style={{ padding: "5px" }}>
                    Siena - Padur, Chennai - 2012
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  role={"button"}
                  src="https://ik.imagekit.io/alamogroup/dev/india%20fund/tr-4-min.png?updatedAt=1719619450055"
                  className="w-100 mb-1 custom-height-fund-img"
                  onClick={() =>
                    setModal({
                      show: true,
                      index: 2,
                      category: "past",
                      images: info,
                    })
                  }
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span style={{ padding: "5px" }}>
                    Catalunya City - Siruseri, Chennai - 2023
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <img
                  role={"button"}
                  src="https://ik.imagekit.io/alamogroup/dev/india%20fund/tr-3-min.png?updatedAt=1719619450094"
                  className="w-100 mb-1 custom-height-fund-img"
                  onClick={() =>
                    setModal({
                      show: true,
                      index: 3,
                      category: "past",
                      images: info,
                    })
                  }
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span style={{ padding: "5px" }}>
                    Patio - Neelambur, Coimbatore - 2024
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* </Fade> */}
          {/* <Fade bottom> */}

          {/* </Fade> */}
        </div>
      </div>
      <div className="sp_sec mb-4">
        {/* <Fade bottom> */}
        <h4 className="fw-bolder text-app-primary">Past Projects*</h4>
        {/* </Fade> */}
        <hr />
        <Tabs defaultActiveKey="catalunya" className="custom_tab3">
          <Tab eventKey="catalunya" title="Catalunya City">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  {/* <Carousel.Item>
                    <img
                      role={"button"}
                      src={cc_1}
                      style={{ maxHeight: "375px" }}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 0,
                          category: "past1",
                          title: "Catalunya City",
                          images: past_projects[0].images,
                        })
                      }
                    />
                  </Carousel.Item> */}
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={cc_2}
                      style={{ maxHeight: "375px" }}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 0,
                          category: "past1",
                          title: "Catalunya City",
                          images: past_projects[0].images,
                        })
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={cc_3}
                      style={{ maxHeight: "375px" }}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 1,
                          category: "past1",
                          title: "Catalunya City",
                          images: past_projects[0].images,
                        })
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={cc_4}
                      style={{ maxHeight: "375px" }}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 2,
                          category: "past1",
                          title: "Catalunya City",
                          images: past_projects[0].images,
                        })
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={cc_5}
                      style={{ maxHeight: "375px" }}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 3,
                          category: "past1",
                          title: "Catalunya City",
                          images: past_projects[0].images,
                        })
                      }
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          Alamo XS Real Catalunya City, Siruseri, Chennai,
                          India, 600130
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          15 acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Total Saleable Area:</b>
                          10,00,000 sft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Status:</b>
                          Phase I & Phase II completed (~ 3,00,000 sft);
                          <ul>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "3.5rem" }}
                            >
                              7,00,000 sft of future phases under development.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Type:</b>
                          Spanish Style Apartments
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Buildings:</b>
                          Phase I : S+4 Floors;
                          <ul>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "5rem" }}
                            >
                              Phase II : B+S+14 Floors;
                            </li>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "5rem" }}
                            >
                              {" "}
                              Phase III & IV : B+S+18 floors.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Units:</b>~ 300 units completed; 700 units under
                          development
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="helios" title="Helios City">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={helios_1}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 0,
                          category: "past1",
                          title: "Helios City",
                          images: past_projects[1].images,
                        })
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={helios_2}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 1,
                          category: "past1",
                          title: "Helios City",
                          images: past_projects[1].images,
                        })
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={helios_3}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 2,
                          category: "past1",
                          title: "Helios City",
                          images: past_projects[1].images,
                        })
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={helios_4}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 3,
                          category: "past1",
                          title: "Helios City",
                          images: past_projects[1].images,
                        })
                      }
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          XS Real Helios City, Padur, Chennai, India, 603103
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          11 acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Total Saleable Area:</b>
                          10,00,000 sft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          Completed in 2012
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Type:</b>
                          Tuscan Style Apartments
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Buildings:</b>
                          Phase I & Phase II : S+4 Floor;
                          <ul>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "5rem" }}
                            >
                              Phase III & Phase IV : S+13 Floors.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Units:</b>
                          Phase I : 296 nos;
                          <ul>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "3rem" }}
                            >
                              Phase II : 228 nos;
                            </li>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "3rem" }}
                            >
                              {" "}
                              Phase III : 228 nos;
                            </li>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "3rem" }}
                            >
                              Phase IV : 140 nos;{" "}
                            </li>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "3rem" }}
                            >
                              (Total : 892 nos) ;completed and handed over.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="laCeleste" title="La Celeste">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={La_Celleste_1}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 0,
                          category: "past1",
                          title: "La Celeste",
                          images: past_projects[2].images,
                        })
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={La_Celleste_2}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 1,
                          category: "past1",
                          title: "La Celeste",
                          images: past_projects[2].images,
                        })
                      }
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Location:</b>
                          XS Real LaCeleste, Porur, Chennai, India, 600125
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          10 acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Total Saleable Area:</b>
                          7,34,000 sft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          Completed in 2008
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Type:</b>
                          French Style Apartments
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Buildings:</b>
                          Low Rise S+4 floor apartments
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Units:</b>~ 426 units completed and handed over
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="bali" title="Villa Bali">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={bali_1}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 0,
                          category: "past1",
                          title: "Villa Bali",
                          images: past_projects[3].images,
                        })
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={bali_2}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 1,
                          category: "past1",
                          title: "Villa Bali",
                          images: past_projects[3].images,
                        })
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={bali_3}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 2,
                          category: "past1",
                          title: "Villa Bali",
                          images: past_projects[3].images,
                        })
                      }
                    />
                  </Carousel.Item>
                  {/* <Carousel.Item>
                    <img  role={"button"} src={bali_4} alt="Alamo" />
                  </Carousel.Item> */}
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          XS Real Villa Bali, Porur, Chennai, India, 600125
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>1 acre
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Total Saleable Area:</b>
                          30,000 sft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          Completed in 2011
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Type:</b>
                          Boutique Luxury Villas
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Buildings:</b>
                          18 Luxury Independent Villas in a group development
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Units:</b>~ 18 units completed and handed over
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="patio" title="Patio">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img
                      role={"button"}
                      src={Patio_1}
                      alt="Alamo"
                      onClick={() =>
                        setModal({
                          show: true,
                          index: 0,
                          category: "past1",
                          title: "Patio",
                          images: past_projects[4].images,
                        })
                      }
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Location:</b>
                          Alamo XS Real Patio, Neelambur, Coimbatore, India,
                          641062
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          4.5 acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Total Saleable Area:</b>
                          3,85,130 sft
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Status:</b>
                          Phase I: completed in 2016;
                          <ul>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "3.5rem" }}
                            >
                              Phase II: Completed in 2017;
                            </li>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "3.5rem" }}
                            >
                              Phase III: Under construction.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Type:</b>
                          Affordable Luxury Apartments
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Buildings:</b>
                          Phase I: S+4 floor low rise apartments;
                          <ul>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "5rem" }}
                            >
                              Phase II: S+4 floor low rise apartments;
                            </li>
                            <li
                              className="mb-0"
                              style={{ paddingLeft: "5rem" }}
                            >
                              Phase III: S+5 floor low rise apartments.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b> Units:</b>~ 510 units (356 units completed and 155
                          units under construction)
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
        {/* <Tabs defaultActiveKey="leader" className="mb-3 custom-css-tabs" fill>
          <Tab eventKey="leader" title="Leadership Team"></Tab>
          <Tab eventKey="track" title="Track Record"></Tab>
          <Tab eventKey="oak" title="Past Projects"></Tab>
        </Tabs> */}
      </div>

      <Modal
        show={modal.show}
        fullscreen
        onHide={() => setModal({ ...modal, show: false })}
      >
        <ModalHeader closeButton>
          {modal.category === "past" ? (
            <>{info[modal.index].title}</>
          ) : (
            <>{modal.title}</>
          )}
        </ModalHeader>
        <ModalBody>
          <Carousel
            className="mb-3"
            slide
            interval={null}
            activeIndex={modal.index}
            onSelect={(e) => {
              setModal({ ...modal, index: e });
            }}
          >
            {/* {(() => {
              if (modal.category === "past") {
                return (
                  <>
                    {info.map((obj, i) => (
                      <Carousel.Item>
                        <img
                          key={`pop-img${i}`}
                          style={{
                            width: "100%",
                            objectFit: "contain",
                            height: "85vh",
                          }}
                          src={obj.src}
                        />
                      </Carousel.Item>
                    ))}
                  </>
                );
              } else {
                return (
                  <>
                    {past_projects
                      .find((o) => o.title === modal.title)
                      .images.map((obj, i) => (
                        <Carousel.Item>
                          <img
                            key={`pop-img${i}`}
                            style={{
                              width: "100%",
                              objectFit: "contain",
                              height: "85vh",
                            }}
                            src={obj.src}
                          />
                        </Carousel.Item>
                      ))}
                  </>
                );
              }
            })()} */}

            {modal.images.map((obj, i) => (
              <Carousel.Item>
                <img
                  key={`pop-img${i}`}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "85vh",
                  }}
                  src={obj.src}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </ModalBody>
      </Modal>
    </>
  );
};

export default People;

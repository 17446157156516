import React, { useState } from "react";
import "./style.scss";
import Header from "../../header";
import Footer from "../../footer";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { forgotPasswordApi } from "../../../client/methods";
import { responseToastMsg } from "../../../utils/response-message";
import Form from "react-bootstrap/Form";
import { validateEmail } from "../../../utils/common";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({
    email: false,
    valid_email: false,
  });
  const [input, setInput] = useState({
    email: "",
  });

  const handleSubmit = async () => {
    if (checkValidation()) {
      try {
        setLoading(true);
        const result = await forgotPasswordApi(input.email);

        setLoading(false);
        responseToastMsg(result?.data?.response_code);
        navigate("/reset-password", {
          state: {
            otp_id: result?.data?.response?.otp_id,
          },
        });
      } catch (err) {
        console.log("🚀 ~ file: index.js ~ line 106 ~ handleSignUp ~ err", err);
        responseToastMsg(err?.data?.error_code);
        setLoading(false);
      }
    }
  };

  const checkValidation = () => {
    let c_validation = { ...validation };

    if (!input.email) {
      c_validation = { ...c_validation, email: true };
    } else {
      if (validateEmail(input.email)) {
        c_validation = { ...c_validation, valid_email: false };
      } else {
        c_validation = { ...c_validation, valid_email: true };
      }
    }

    setValidation(c_validation);

    if (!c_validation.email && !c_validation.valid_email) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeEvent = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    if (e.target.value) {
      setValidation({ ...validation, [e.target.name]: false });
    } else {
      setValidation({ ...validation, [e.target.name]: true });
    }
  };

  const handleKeyPressEvent = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <Header />
      <div className="login_wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="l_inner">
                <h2>Reset Your Password</h2>
                <p>
                  Enter the email address associated with your account and we'll
                  send you a link to reset your password.
                </p>
                <form>
                  <div className="form-group mb-4">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="john@domain.com"
                      required={validation.email}
                      onChange={handleChangeEvent}
                      onKeyPress={handleKeyPressEvent}
                    />
                    {(validation.email || validation.valid_email) && (
                      <p className="text-danger">
                        Please enter a valid email address
                      </p>
                    )}
                  </div>

                  <div className="text-center">
                    <button
                      disabled={loading}
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-primary"
                    >
                      {loading ? " Sending Email" : "Send Email"}
                    </button>
                  </div>
                </form>
                <p className="mt-4 text-center">
                  <Link to="/signin">
                    <u>Return to Sign In</u>
                  </Link>
                </p>
                <p className="text-center fs-little">
                  If you require additional help, please contact us at
                  ir@alamoequity.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;

import { useState } from "react";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { ChangePasswordSchema } from "../../utils/schema";
import { changeInvestorPassword } from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import AppPasswordInput from "../../components/app-password-input";
const ChangePassword = (user) => {
  const navigate = useNavigate();
  const onSubmit = async (values) => {
    const { old_password, confirm_password } = values;

    try {
      const result = await changeInvestorPassword({
        id: user.user.user_id,
        old_password: old_password,
        confirm_password: confirm_password,
      });
      responseToastMsg(result.data.response_code);
      navigate("/logout");
    } catch (error) {
      responseToastMsg(error.data.error_code);
    }
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="s_detailed">
        <h1>Change Password</h1>
        <div className="row">
          <div className="col-md-12">
            <div className="box_1">
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-3">
                  <div className="form-group">
                    <label>Old Password</label>
                    <AppPasswordInput
                      placeholder="Enter Old Password"
                      size="sm"
                      value={formik.values.old_password}
                      name="old_password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.old_password &&
                        (formik.errors.old_password
                          ? "is-invalid"
                          : !formik.errors.old_password && "is-valid")
                      }`}
                    />
                    {formik.errors.old_password &&
                      formik.touched.old_password && (
                        <p className="text-danger">
                          {formik.errors.old_password}
                        </p>
                      )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-3">
                  <div className="form-group">
                    <label>New Password</label>
                    <AppPasswordInput
                      placeholder="Enter New Password"
                      size="sm"
                      value={formik.values.new_password}
                      name="new_password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.new_password &&
                        (formik.errors.new_password
                          ? "is-invalid"
                          : !formik.errors.new_password && "is-valid")
                      }`}
                    />

                    {formik.errors.new_password &&
                      formik.touched.new_password && (
                        <p className="text-danger">
                          {formik.errors.new_password}
                        </p>
                      )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-3">
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <AppPasswordInput
                      placeholder="Enter Confirm Password"
                      size="sm"
                      value={formik.values.confirm_password}
                      name="confirm_password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.confirm_password &&
                        (formik.errors.confirm_password
                          ? "is-invalid"
                          : !formik.errors.confirm_password && "is-valid")
                      }`}
                    />

                    {formik.errors.confirm_password &&
                      formik.touched.confirm_password && (
                        <p className="text-danger">
                          {formik.errors.confirm_password}
                        </p>
                      )}
                  </div>
                </div>
                <div className="col-md-12 text-center mt-3">
                  <button className="btn btn-primary" type="submit">
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChangePassword;

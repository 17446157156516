import "./style.scss";
import Header from "../header";
import Footer from "../footer";
import Project_1 from "../../images/project_1.jpg";
import { FaMapMarkerAlt } from "react-icons/fa";

const SingleProperty = () => {
  return (
    <>
      <Header />

      <div className="s_header">
        <div className="container s_header_inr">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h1>Park 375 Industrial - Phase II</h1>
              <p className="m-0">
                {" "}
                <FaMapMarkerAlt /> Somerville, MA
              </p>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center justify-content-between">
                <p className="m-0 col-md-9">
                  Rare and lucrative pre-development project strategically timed
                  to hedge volatility. Mixed-use campus surrounded by new
                  development in the world’s leading life sciences market.
                </p>
                <p className="button_p m-0 col-md-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => window.open("/invest", "_self")}
                  >
                    Invest Now
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="property_de1">
        <div className="container property_de1_inr">
          <div className="row">
            <div className="col-md-8">
              <div className="gallery">
                <img src={Project_1} alt="Gallery" />
              </div>
              <div className="head_nav">
                <ul>
                  <li>
                    <a className="active" href="#Investment">
                      Investment
                    </a>
                  </li>
                  <li>
                    <a href="#Plan">Business Plan</a>
                  </li>
                  <li>
                    <a href="#Property">Property</a>
                  </li>
                  <li>
                    <a href="#Location">Location</a>
                  </li>
                  <li>
                    <a href="#Sponsor">Sponsor</a>
                  </li>
                  <li>
                    <a href="#Disclaimers">Disclaimers</a>
                  </li>
                </ul>
              </div>

              <div className="dtail_ct mt-5">
                <h2 className="sec_t">Offering Summary</h2>
                <ul>
                  <li>
                    9x Repeat Sponsor with $1.0 billion+ in Southeastern
                    multifamily transaction experience. Atlanta is one of IMG’s
                    primary Southeastern markets of focus, home to three other
                    IMG-owned assets among its current portfolio. Two of the
                    Sponsor’s deals through Crowdstreet (Sommerset - Raleigh, NC
                    and Canopy - Charlotte, NC) have been fully realized and
                    significantly outperformed underwritten projections.
                  </li>
                  <li>
                    Property appraised for $1.8M above the purchase price.
                    Appraised value suggests immediate value prior to completion
                    of the value-add business plan.
                  </li>
                  <li>
                    IMG secured a highly favorable 57.1% loan-to-cost senior
                    loan at a fixed, interest-only 4.79% rate. A huge risk
                    mitigant for the project, with a loan term that matures well
                    beyond the projected hold period, results in a project that
                    can cover its debt service at underwritten assumptions with
                    as low as a 72% occupancy rate.
                  </li>
                </ul>
              </div>

              <div className="divider"></div>
              <div className="dtail_ct mt-5">
                <h2 className="sec_t">The Investment</h2>
                <p>
                  Investors Management Group, Inc. ("Sponsor" or "IMG"), a
                  California corporation, is hereby offering to accredited
                  investors membership interests (the "Membership Interests") in
                  Wynnwood SPE, LLC, a Delaware limited liability company (the
                  "Company"), which will indirectly own an interest in the
                  Property, a 320-unit multifamily apartment community known as
                  "Wynnwood Vinings" located at 1900 Tamarron Parkway SE,
                  Atlanta, Georgia 30339 (the "Property"), and undivided tenant
                  in common interests (the "TIC Interests") in that certain
                  Property. The ownership objectives for the Property will be to
                  (i) capitalize on organic rent growth in an appreciating
                  submarket; (ii) upgrade unit interiors and common areas to
                  achieve rent premiums; (iii) maximize operating performance
                  with strategic asset management guidance; and (iv) preparing
                  the Property to be sold in approximately five (5) years.
                </p>
                <p>
                  The Company will itself retain an undivided TIC Interest in
                  the Property through a wholly owned subsidiary, Wynnwood
                  Vinings, LLC, a Delaware limited liability company (TIC 1).
                  The Company is non-member managed by IMG. IMG will make all
                  management decisions for the Company. The Owners will enter
                  into the Asset Management Agreement and hire IMG to act as the
                  asset manager (Asset Manager). IMG will assign or delegate
                  some or all of the day-to-day management responsibilities of
                  the Property to ZRS Management (Property Manager), who
                  presently manages IMG’s other properties in Atlanta, GA.
                </p>
              </div>

              <div className="divider"></div>
              <div className="dtail_ct mt-5">
                <h2 className="sec_t">The Business Plan</h2>
                <h3>The Property</h3>
                <ul>
                  <li>
                    1985-vintage, garden-style, 320-unit multifamily property
                    located in the affluent Cumberland submarket of Atlanta, GA
                  </li>
                  <li>Current Occupancy: 94.7%</li>
                  <li>
                    Status: The Sponsor closed on the Property in October 2022
                  </li>
                </ul>
                <h3>The Project</h3>
                <ul>
                  <li>
                    Completing the renovation begun by the seller, which
                    includes $1.9 million in interior and exterior upgrades. IMG
                    will complete the following:
                    <ul>
                      <li>
                        Full renovation of remaining “Classic” units: 130 units
                      </li>
                      <li>
                        Partial renovation of previously upgraded units: 190
                        units
                      </li>
                      <li>
                        Washer/dryer installation in all units without a
                        washer/dryer: 231 units
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <div className="main_table">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Targeted Investor IRR</td>
                      <td>13.4%</td>
                    </tr>
                    <tr>
                      <td>Targeted Equity Multiple</td>
                      <td>2.0x</td>
                    </tr>
                    <tr>
                      <td>Targeted Average Cash Yield</td>
                      <td>6.1%</td>
                    </tr>
                    <tr>
                      <td>Targeted Investment Period</td>
                      <td>4 Years</td>
                    </tr>
                    <tr>
                      <td>Property Type</td>
                      <td>Multifamily</td>
                    </tr>
                    <tr>
                      <td>City, State | MSA</td>
                      <td>Alexandria, VA</td>
                    </tr>
                    <tr>
                      <td>Investment Profile</td>
                      <td>Value-Add</td>
                    </tr>
                    <tr>
                      <td>Targeted Project IRR</td>
                      <td>25.2%</td>
                    </tr>
                    <tr>
                      <td>Sponsor Co-Invest ($)</td>
                      <td>$6,128,990</td>
                    </tr>
                    <tr>
                      <td>Sponsor Co-Invest (%)</td>
                      <td>55%</td>
                    </tr>
                    <tr>
                      <td>Offers Due</td>
                      <td>12/2/22</td>
                    </tr>
                    <tr>
                      <td>Funds Due</td>
                      <td>12/5/22</td>
                    </tr>
                    <tr>
                      <td>Targeted Distribution Start Date</td>
                      <td>Q1 2023</td>
                    </tr>
                    <tr>
                      <td>Preferred Return Accrual Date</td>
                      <td>Effective on date of closing</td>
                    </tr>
                    <tr>
                      <td>Minimum Investment</td>
                      <td>$25,000</td>
                    </tr>
                    <tr>
                      <td>SD-IRA Investments</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>Initial Posting Date</td>
                      <td>10/28/22</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SingleProperty;

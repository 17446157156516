import React from "react";
import Fade from "react-reveal/Fade";

import "./style.scss";

const Disclaimers = () => {
  return (
    <>
      <div className="disclaimer_sec">
        {/* <Fade bottom> */}
        <h4 className="fw-bolder text-app-primary">Disclaimer</h4>
        {/* </Fade> */}
        <hr />
        <div className="section-desc text-justify">
          {/* <Fade bottom> */}
          <p>
            The content on this detail page was produced by the Alamo Group or
            an affiliate thereof. The Alamo Group is under no obligation to
            update this detail page.
          </p>
          {/* </Fade>{" "}
          <Fade bottom> */}
          <p>
            The preceding summary of principal terms of the offering is
            qualified in its entirety by reference to the more complete
            information about the offering contained in the offering documents,
            including, without limitation, the Confidential Private Placement
            Memorandum, the Limited Partnership Agreement for Alamo India
            Fund 1, LP (the “Partnership”), the Subscription Documents and all
            exhibits and other documents attached thereto or referenced therein
            (collectively, the "Investment Documents"). This summary is not
            complete, and each prospective investor should carefully read all of
            the Investment Documents and any supplements thereto, copies of
            which are available by clicking the links above or upon request,
            before deciding whether to make an investment. In the event of an
            inconsistency between the preceding summary and the Investment
            Documents, investors should rely on the content of the Investment
            Documents.
          </p>
          {/* </Fade>{" "}
          <Fade bottom> */}
          <p>
            The Presentation does not constitute an offer to sell, or a
            solicitation of an offer to buy, any security and may not be relied
            upon in connection with the purchase or sale of any security. Any
            such offer would only be made by means of formal offering documents,
            the terms of which would govern in all respects; the Formal Offering
            documents are only accessible after creating an account with Alamo
            Group. You are cautioned against using this information as the basis
            for making a decision to purchase any security or to otherwise
            engage in an investment advisory relationship with Alamo Equity.
            Securities will only be offered through confidential offering
            materials delivered to accredited investors and will be offered and
            sold pursuant to an exemption from registration under the Securities
            Act only to persons who are accredited investors (within the meaning
            of SEC Rule 501 under Regulation D).{" "}
          </p>
          {/* </Fade>{" "}
          <Fade bottom> */}
          <p>
            There can be no assurance that the methodology used for calculating
            targeted IRR is appropriate or adequate. Target IRR is presented
            solely for the purpose of providing insight into the Partnership's
            investment objectives, detailing its anticipated risk and reward
            characteristics and for establishing a benchmark for future
            evaluation of the Partnership's performance. Targeted IRR is not a
            predictor, projection or guarantee of future performance. There can
            be no assurance that the Partnership's targets will be met or that
            the Partnership will be successful in identifying and investing in
            projects that would allow the Partnership to meet these return
            parameters. Target returns should not be used as a primary basis for
            an investor's decision to invest in the Partnership. Please see the
            applicable Investment Documents for disclosure relating to
            forward-looking statements.
          </p>
          {/* </Fade>{" "}
          <Fade bottom> */}
          <p>
            This material contains forward-looking statements. Such statements
            are based upon the Alamo Group’s current expectations, beliefs, and
            assumptions about future events, and are other than statements of
            historical fact, and involve a number of risks and uncertainties.
            The use in this material of words such as “believe,” “anticipate,”
            “approximately,” “expect,” “intend,” “plan,” “estimate,” and similar
            expressions are intended to identify forward-looking statements, but
            are not the exclusive means of identifying such statements. The
            Fund's actual results of operations may differ materially from those
            contained in the forward-looking statements herein. All
            forward-looking statements attributable to the Alamo Group or
            persons acting on its behalf apply only as of the date of the
            offering and are expressly qualified in their entirety by the
            cautionary statements included elsewhere in this summary and the
            Investment Documents. Any financial projections are preliminary and
            subject to change; the Alamo Group undertakes no obligation to
            update or revise these forward-looking statements to reflect events
            or circumstances that arise after the date made or to reflect the
            occurrence of unanticipated events. Inevitably, some assumptions
            will not materialize, and unanticipated events and circumstances may
            affect the ultimate financial results. Projections are inherently
            subject to substantial and numerous uncertainties and to a wide
            variety of significant business, economic and competitive risks, and
            the assumptions underlying the projections may be inaccurate in any
            material respect. This material contains forward-looking statements.
            Therefore, the actual results achieved may vary significantly from
            the forecasts, and the variations may be material.
          </p>
          {/* </Fade>
          <Fade bottom> */}
          <p>
            The Alamo India Fund 1, LP will not be registered under the
            Securities Act of 1933, as amended (the "Securities Act") in
            reliance upon exemptions contained in 506(c) of Regulation D as
            promulgated under the Securities Act. In addition, the interests
            will not be registered under any state securities laws in reliance
            on exemptions from registration. Such interests are subject to
            restrictions on transferability and resale and may not be
            transferred or resold except as permitted pursuant to the Investment
            Documents, specifically, the LP Agreement, or under applicable state
            and federal securities laws pursuant to registration or an available
            exemption.
          </p>
          {/* </Fade>
          <Fade bottom> */}
          <p>
            All investing activities risk the loss of capital. There can be no
            assurance that investors will not suffer significant losses. No
            guarantee or representation is made that investment objectives of
            the Alamo India Fund 1, LP will be achieved. You should not
            subscribe to purchase interests in Alamo India Fund 1, LP unless
            you can readily bear the consequences of such loss.
          </p>
          {/* </Fade> */}
        </div>
      </div>
    </>
  );
};

export default Disclaimers;

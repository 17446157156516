import React, { useState, useRef, useEffect } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Switch from "react-switch";
import Logo from "../../images/logo.png";
import Carousel from "react-bootstrap/Carousel";
import Money from "../../images/skyline.png";
import Loan from "../../images/loan.png";
import Target from "../../images/target.png";
import { FaArrowRight, FaRegFilePdf } from "react-icons/fa";
import { BiBuildingHouse } from "react-icons/bi";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  createInterest,
  getListing,
  getListingByPageId,
  getMultiDocument,
  getMultiProperty,
  logDownloads,
} from "../../client/methods";

import "./style.scss";
import toast from "react-hot-toast";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import {
  currencyFormat,
  numberFormatter,
  numberFormatterSellingPrice,
  numberFormatterOnly,
  numberCommaFormatter,
  numberFormatterTable,
  numberCommaTableFormatter,
} from "../../utils/common";
import { useFormik } from "formik";
import { interestSchema } from "../../utils/schema";
import { responseToastMsg } from "../../utils/response-message";
import { useDispatch, useSelector } from "react-redux";
import People from "../../components/people/index";
import Disclaimers from "../../components/disclaimers";
import CallToAction from "../../components/call-to-action";
import { changeCurrencyThunk } from "../../redux/thunk/user.thunk";

// Static data

import st_page from "../../data/page.json";
import st_property from "../../data/properties.json";
import st_documents from "../../data/documents.json";

const NewOfferProperty = () => {
  const { page_id } = useParams();

  const { hash } = useLocation();

  const dispatch = useDispatch();

  const offeringRef = useRef(null);
  const phaseRef = useRef(null);
  const groupRef = useRef(null);
  const leaderRef = useRef(null);
  const trackRef = useRef(null);
  const fundRef = useRef(null);

  const scrollToTargetAdjusted = (element) => {
    var headerOffset = 25;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleScrollPosition = (input_hash) => {
    switch (input_hash) {
      case "#offering":
        scrollToTargetAdjusted(offeringRef.current);
        break;
      case "#projects":
        scrollToTargetAdjusted(phaseRef.current);
        break;
      case "#alamo":
        scrollToTargetAdjusted(groupRef.current);
        break;
      case "#leadership_team":
        scrollToTargetAdjusted(leaderRef.current);
        break;
      case "#track_record":
        scrollToTargetAdjusted(trackRef.current);
        break;
      case "#fund_notes":
        scrollToTargetAdjusted(fundRef.current);
        break;

      default:
        return null;
    }
  };

  const [hello, setHello] = useState(true);

  useEffect(() => {
    setTimeout(handleScrollPosition(hash), 5000);
  }, [hello]);

  const user = useSelector((state) => state.user.data);

  const user_redux = useSelector((state) => state.user);

  const [listing, setListing] = useState();
  const [showInterest, setShowInterest] = useState(false);
  const [showWebinar, setShowWebinar] = useState(false);

  const [projectData, setProjectData] = useState({
    target_raise: 0,
    total_portfolio_value: 0,
  });

  const getListingDetails = async () => {
    try {
      // var result;
      // if (page_id) {
      //   result = await getListingByPageId(page_id);
      // } else {
      //   result = await getListingByPageId("default-id");
      // }
      // setListing(result.data.response);

      setListing(st_page);
      setHello(!hello);
    } catch (error) {
      console.log("🚀 ~ file: index.js:20 ~ getListingDetails ~ error", error);
    }
  };

  useEffect(() => {
    getListingDetails();
  }, [hash]);

  const handleInvest = () => {
    window.open("/signup?type=invest&page_id=" + listing?.page_id, "_blank");
  };

  const init = {
    name: "",
    email: "",
    listing_id: "",
    offering_amount: "",
  };

  const onSubmit = async (values) => {
    try {
      await createInterest({ ...values, listing_id: listing?._id });

      toast.success(
        "We appreciate your interest in the project. We advise signing up for the webinar also."
      );
      setShowInterest(false);
      formik.resetForm();
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log("🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error", error);
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: interestSchema,
    onSubmit,
  });

  const handleUpdateInput = (e, check_number = true) => {
    if (check_number) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        formik.setFieldValue("offering_amount", e.target.value);
      }
    } else {
      formik.setFieldValue("offering_amount", e.target.value);
    }
  };

  const handleShowInterest = () => {
    window.open("/signup?type=interest&page_id=" + listing?.page_id, "_blank");
    // setShowInterest(true);
    // console.log(user);
    // user &&
    //   formik.setValues({
    //     ...formik.values,
    //     name: user.name,
    //     email: user.email,
    //   });
  };

  return (
    <>
      <div className="property_wrapper_v2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-end mt-4">
              {/* <span className="badge rounded-pill text-bg-secondary mt-1 mb-1 me-2">
                1 USD = {listing?.usd_value} INR
              </span> */}
              {/* <span
                className="badge rounded-pill text-bg-info mt-1 mb-1"
                role={"button"}
                onClick={() => {
                  dispatch(
                    changeCurrencyThunk(
                      user_redux.currency === "INR" ? "USD" : "INR"
                    )
                  );
                }}
              >
                {user_redux.currency === "INR" ? (
                  <>
                    INR <FaArrowRight size={10} style={{ marginTop: "-2px" }} />{" "}
                    USD
                  </>
                ) : (
                  <>
                    USD <FaArrowRight size={10} style={{ marginTop: "-2px" }} />{" "}
                    INR
                  </>
                )}
              </span> */}

              {/* <span
                className="badge rounded-pill text-bg-light mt-1 mb-1 border"
                role={"button"}
                // onClick={() => {
                //   dispatch(
                //     changeCurrencyThunk(
                //       user_redux.currency === "INR" ? "USD" : "INR"
                //     )
                //   );
                // }}
              >
                {
                  <>
                    INR{" "}
                    <Switch
                      checked={user_redux.currency === "USD"}
                      onChange={() => {
                        dispatch(
                          changeCurrencyThunk(
                            user_redux.currency === "INR" ? "USD" : "INR"
                          )
                        );
                      }}
                      onColor="#888888"
                      onHandleColor="#4991fc"
                      offHandleColor="#4991fc"
                      handleDiameter={14}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      height={10}
                      width={30}
                      className="react-switch"
                    />{" "}
                    USD
                  </>
                }
              </span> */}

              <div className="p_header" style={{ marginTop: 0 }}>
                <div className="logo">
                  <Link to="/">
                    <img src={Logo} alt="" width={150} />
                  </Link>
                </div>
                <div className="head_btn">
                  <div className="text-header-title text-center">
                    <div className="row">
                      <div className="col">
                        <button
                          className="btn btn-light me-3 mb-2"
                          type="button"
                          onClick={handleShowInterest}
                        >
                          Express Interest
                        </button>
                        {/* <button
                          className="btn btn-light me-3 mb-2"
                          type="button"
                          onClick={() => setShowWebinar(true)}
                        >
                          Register for Webinar
                        </button> */}
                        <button
                          className="btn btn-primary mb-2 custom-invest-btn"
                          type="button"
                          onClick={handleInvest}
                        >
                          Invest Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="head_btn_sm">
                <div className="text-header-title text-center">
                  <div className="row">
                    <div className="col">
                      <button
                        className="btn btn-outline-primary me-3 mb-2"
                        type="button"
                        onClick={handleShowInterest}
                      >
                        Express Interest
                      </button>
                      {/* <button
                        className="btn btn-outline-primary me-3 mb-2"
                        type="button"
                        onClick={() => setShowWebinar(true)}
                      >
                        Register for Webinar
                      </button> */}
                      <button
                        className="btn btn-primary mb-2 ps-4 pe-4"
                        type="button"
                        onClick={handleInvest}
                      >
                        Invest Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Fade bottom> */}
          <div className="row s_header align-items-center justify-content-center">
            <div className="col-12 h_header order-md-2 d-flex justify-content-between">
              <p className="h_text">NEWEST OFFERING</p>
              <p>
                {user_redux.login ? (
                  <a className="" href="/profile">
                    <span> Hello, {user.name}</span>
                  </a>
                ) : (
                  <a className="" href="/signin">
                    <span> Sign In</span>
                  </a>
                )}
              </p>
            </div>
            <div className="col-12 order-md-2">
              <h2>{listing?.title}</h2>
            </div>
            {/* <div className="col-12 order-md-1 s_btn mb-2">
                <button
                  className="btn btn-outline-primary me-2 mb-2"
                  type="button"
                  onClick={handleShowInterest}
                >
                  Express Interest
                </button>
                <button
                  className="btn btn-outline-primary me-2 mb-2"
                  type="button"
                  onClick={() => setShowWebinar(true)}
                >
                  Register for Webinar
                </button>
                <button
                  className="btn btn-primary me-2 mb-2"
                  style={{ background: "#0d6efd !important" }}
                  type="button"
                  onClick={handleInvest}
                >
                  Invest Now
                </button>
              </div> */}
          </div>
          {/* </Fade> */}
          {/* <Fade bottom> */}
          <div className="row s_banner">
            <div className="col-12">
              {/* <ProjectImages projects={listing?.properties} /> */}
              <ProjectImagesList docsIds={listing?.listing_images} />
            </div>
          </div>
          {/* </Fade> */}
          {/* <div className="row s_banner">
            <div className="col-12">
              <div className="sbanner_inr">
                <img src={Property} alt="Propery"></img>
                <div className="cnt">
                  <h2>Secure Your Spot Today</h2>
                  <p>More Details Coming Soon.</p>
                </div>
              </div>
            </div>
          </div> */}
          {/* <Fade bottom cascade> */}
          <div className="row s_feature" id="fund-note-1">
            <div className="col-md-4">
              <div className="f_sec">
                <div className="icon">
                  <img src={Target} alt="Exit Icon" />
                </div>
                <div className="cont">
                  <h3>
                    Target Equity Raise
                    <a href="#fund-notes" className="hyp-link">
                      <sup className="sup-cus">1</sup>
                    </a>
                  </h3>
                  <h4>
                    {
                      numberFormatter(
                        projectData?.target_raise,
                        user_redux.currency,
                        listing?.usd_value
                      ).split(".")[0]
                    }
                  </h4>
                  {/* <div className="fund-link">
                      <a href="#fund-notes" className="hyp-link">
                        Fund Note 1
                      </a>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="f_sec">
                <div className="icon">
                  <img src={Money} alt="Exit Icon" />
                </div>
                <div className="cont">
                  <h3>
                    Portfolio Value Revenue
                    <a href="#fund-notes" className="hyp-link">
                      <sup className="sup-cus">2</sup>
                    </a>
                  </h3>
                  <h4>
                    {
                      numberFormatter(
                        projectData?.total_portfolio_value,
                        user_redux.currency,
                        listing?.usd_value
                      ).split(".")[0]
                    }
                  </h4>
                  {/* <div className="fund-link">
                      <a href="#fund-notes" className="hyp-link">
                        Fund Note 2
                      </a>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="f_sec">
                <div className="icon">
                  <img src={Loan} alt="Exit Icon" />
                </div>
                <div className="cont">
                  <h3>
                    Minimum Investment
                    <a href="#fund-notes" className="hyp-link">
                      <sup className="sup-cus">3</sup>
                    </a>
                  </h3>
                  <h4>
                    {/* {
                      currencyFormat(
                        listing?.minimum_investment,
                        user_redux.currency,
                        listing?.usd_value
                      ).split(".")[0]
                    } */}
                    {user_redux.currency === "USD" ? "$50,000" : "₹25,00,000"}
                  </h4>
                  {/* <div className="fund-link">
                      <a href="#fund-notes" className="hyp-link">
                        Fund Note 3
                      </a>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* </Fade>
          <Fade bottom> */}
          <h4 className="mb-4 text-center" ref={phaseRef}>
            Anticipated Capital Development by Projects{" "}
            <a href="#fund-notes" className="hyp-link">
              <sup className="sup-cus">4</sup>
            </a>
          </h4>
          <div className="table table-responsive custom-border-primary mb-3">
            <ProjectsTable
              usdValue={listing?.usd_value}
              setProjectData={setProjectData}
              projects={listing?.properties}
              // projects={["63eb29a685c872128de7a778"]}
            />
          </div>
          {/* </Fade> */}
          {/* 
          <Fade bottom>
            <div className="phase">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="order-md-2 text-right">
                  Raise from January 2023 to June 2023
                </div>
                <div className="order-md-1">
                  <h4>Phase 1: $60 Million Equity</h4>
                </div>
              </div>

              <div className="table table-responsive">
                <ProjectsPhaseTable
                  projects={[
                    "638df2bde56a1b3c6896bc11",
                    "638fff9ed4392a25dfa378bb",
                  ]}
                />
              </div>
            </div>
          </Fade>

          <Fade bottom>
            <div className="phase">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="order-md-2 text-right">
                  Raise from July 2023 to December 2023
                </div>
                <div className="order-md-1">
                  <h4>Phase 2: $40 Million Equity</h4>
                </div>
              </div>
              <div className="table table-responsive">
                <ProjectsPhaseTable
                  projects={[
                    "639000aed4392a25dfa378d2",
                    "63900143d4392a25dfa378e9",
                  ]}
                />
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="phase">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="order-md-2 text-right">
                  Raise from January 2024 to June 2024
                </div>
                <div className="order-md-1">
                  <h4>Phase 3: $50 Million Equity</h4>
                </div>
              </div>

              <div className="table table-responsive">
                <ProjectsPhaseTable
                  projects={[
                    "638f9448dd0e1ed6c0c8737d",
                    "6390040fd4392a25dfa3791e",
                  ]}
                />
              </div>
            </div>
          </Fade> */}
          {/* 
          <div className="row s_more mb-4">
            <div className="col">
              <Fade bottom cascade>
                <p className="d-flex wrap-custom mb-0 justify-content-center">
                  <button
                    className="btn btn-primary me-2 mb-2 text-nowrap btn-primary-theme "
                    type="button"
                    onClick={handleShowInterest}
                  >
                    Express Interest
                  </button>
                  <button
                    className="btn btn-primary me-2 mb-2 text-nowrap btn-primary-theme "
                    type="button"
                    onClick={() => setShowWebinar(true)}
                  >
                    Register for Webinar
                  </button>
                  <button
                    className="btn btn-primary mb-2 text-nowrap btn-primary-theme "
                    type="button"
                    onClick={handleInvest}
                  >
                    Invest Now
                  </button>
                </p>
              </Fade>
            </div>
          </div> */}

          {/* <Fade bottom> */}
          <div className="row s_header align-items-center justify-content-center mt-0">
            <div className="col-12 order-md-1 s_btn mb-2 text-center">
              <button
                className="btn btn-outline-primary me-2 mb-2"
                type="button"
                onClick={handleShowInterest}
              >
                Express Interest
              </button>
              {/* <button
                className="btn btn-outline-primary me-2 mb-2"
                type="button"
                onClick={() => setShowWebinar(true)}
              >
                Register for Webinar
              </button> */}
              <button
                className="btn btn-primary me-2 mb-2"
                style={{ background: "#0d6efd !important" }}
                type="button"
                onClick={handleInvest}
              >
                Invest Now
              </button>
            </div>
          </div>
          {/* </Fade> */}
          <div className="row s_more">
            {/* <Fade bottom> */}
            <div className="col-md-4 mb-md-0 mb-4  order-md-2">
              <div className="h_section">
                <div className="divide-section">
                  <h4>Offering Highlights</h4>
                  <hr />
                  <p>
                    Target Investor IRR: <b>{listing?.target_irr}</b>
                  </p>
                  <p>
                    Target Equity Multiple:{" "}
                    <b>{listing?.target_equity_multiple}</b>
                  </p>{" "}
                  <p>
                    Targeted Investment Period:{" "}
                    <b>{listing?.targeted_investment_period}</b>
                  </p>
                </div>
                <div className="d-none d-md-block">
                  <DeckDocs input={listing} />
                </div>
              </div>
            </div>
            {/* </Fade> */}

            <div className="col-md-8 order-md-1" id="offering">
              <div className="mr_over">
                {/* <Fade bottom> */}
                <h4 className="fw-bolder text-app-primary" ref={offeringRef}>
                  Offering Description
                  <a href="#fund-notes">
                    <sup className="sup-cus">5</sup>
                  </a>
                </h4>
                {/* </Fade> */}
                <hr />
                {/* <Fade bottom cascade> */}
                <div>
                  <p className="text-justify offering-desc">
                    Alamo India Fund 1, LP, (the "Fund") will be an investment
                    vehicle focused on funding acquisition capital for single
                    purpose entities that own or intends to purchase land to
                    develop residential townships across Chennai and Coimbatore.
                    The Fund was formed through the efforts of Logan Anjaneyulu,
                    (the "Founder"), Alamo India Fund GP, LLC, (the
                    &quot;General Partner&quot;), Alamo Equity, LLC (the
                    "Investment Manager") and Alamo XS Real Private Limited (the
                    "Developer" or "AXS" and, collectively with Alamo Equity and
                    the General Partner, the "Alamo Group"). The Alamo Group,
                    through the Fund as an investment vehicle, intends to raise
                    up to $ 25 Million (target) in equity to develop
                    approximately 2.41 Mn.sft of high-quality residential
                    townships across Chennai and Coimbatore, India. The
                    investment projects currently being considered for this
                    portfolio are:
                  </p>

                  <p className="text-justify offering-desc mt-4 mb-4">
                    <ul>
                      <li>
                        <b>Water Front Towers</b> - 15 Acres Residential
                        township development; 1st phase of the project is
                        completed and handed over; Development of Phase II in
                        balance 10.1 acres with approvals in place and ready to
                        launch; 1154 units; 6 high rise towers of B+S+17 floors;
                        on Vandalur - Kelambakkam Road, Off GST in South Chennai
                        (the fastest growing residential hub of Chennai) in
                        close proximity to upcoming bus terminus and OMR (the
                        largest IT corridor of Chennai).
                      </li>
                      {/* <li>
                        <b>The Calisto</b> - 6 Acres Residential High rise
                        township development as a Joint Venture with Land
                        owner(Approval under process); 685 Units; B+S+14 floor
                        muti tower township; in Chettipunyam off GST Road,
                        Opposite to Mahindra World City SEZ(The largest
                        Integrated Business City in Chennai)
                      </li> */}
                      <li>
                        <b>English Meadows</b> - 4.85 Acres land; low rise
                        residential township development as a Joint Venture with
                        the land owner (Approvals under process); 400 Units; S+5
                        Floor multi tower township; in Ondipudur, Trichy Road,
                        Coimbatore. The land is in very close proximity to the
                        Center of the city driving large demand for residential
                        housing.
                      </li>
                      <li>
                        <b>Alamo Signature Towers</b> - 3.5 Acres Land (Land
                        acquisition in process); 400 Units; B+S+18 Floor high
                        rise multi tower township; right on Poonamalle Road,
                        Chennai. Poonamalle falls in Mount-Poonamalle Road which
                        is the emerging IT Corridor of Chennai with ~ 5Mn.sft of
                        office space coming up. Metro Rail infrastructure
                        expected to connect Poonamalle with rest of Chennai by
                        2024.
                      </li>
                      <li>
                        <b>Alamo Titanium Towers</b> - 1.06 Acres land; Boutique
                        high rise residential development as a Joint Venture
                        with the existing owners; Approvals obtained; 144 Units;
                        B+S+9 Floors single tower Development; in Anna Nagar,
                        Chennai. The project is in very close proximity to many
                        developed residential communities and commercial
                        establishments.
                      </li>
                      {/* <li>
                        <b>Catalunya City - Ph IV</b> - Part of 15-acre Spanish
                        Themed community in Chennai; Phase I &amp; II of the
                        project are completed with all the existing
                        infrastructure in place. 2.13 acre land (acquisition in
                        process); 600 units; two towers of B+S+19 floor ; Right
                        behind Siruseri IT park which houses largest campus of
                        TCS in south India.
                      </li> */}
                    </ul>
                  </p>
                  <p className="text-justify offering-desc">
                    Through this fund, the Alamo Group anticipates raising
                    approximately $25 Million in equity capital to deploy into
                    special purpose entities for the above four or other similar
                    projects.
                    <a href="#fund-notes">
                      <sup className="sup-cus-low">5</sup>
                    </a>{" "}
                  </p>

                  <p className="text-justify offering-desc">
                    The minimum investment amount is{" "}
                    {user_redux.currency === "USD" ? "$50,000" : "₹25,00,000"}.
                    <a href="#fund-notes">
                      <sup className="sup-cus-low">3</sup>
                    </a>
                  </p>
                </div>
                {/* </Fade> */}
              </div>

              <div className="h_section d-md-none">
                {/* <Fade bottom cascade> */}
                <DeckDocs input={listing} />
                {/* </Fade> */}
              </div>
            </div>
          </div>

          {/* <Fade bottom>
            <div className="table table-responsive">
              <ProjectsTableOnlyName
                setProjectData={setProjectData}
                // projects={listing?.properties}
                projects={[
                  "638df2bde56a1b3c6896bc11",
                  "638fff9ed4392a25dfa378bb",
                  "639000aed4392a25dfa378d2",
                  "63900143d4392a25dfa378e9",
                  "638f9448dd0e1ed6c0c8737d",
                  "6390040fd4392a25dfa3791e",
                ]}
              />
            </div>
          </Fade> */}
          <div className="row s_more">
            <div className="col">
              <People
                leaderRef={leaderRef}
                groupRef={groupRef}
                trackRef={trackRef}
              />
              <div className="notes-addon" id="fund-notes" ref={fundRef}>
                {/* <Fade bottom> */}
                <h4 className="fw-bolder">Fund Notes</h4>
                {/* </Fade> */}
                <hr />
                {/* <Fade bottom> */}
                <p className="text-justify offering-desc mb-3">
                  <ul>
                    <li>
                      <b>
                        <a href="#fund-note-1">Fund Note 1</a>: Target Equity
                        Raise
                        <sup className="sup-cus">1</sup>
                      </b>{" "}
                      - The Target Equity Raise is up to{" "}
                      {user_redux.currency === "USD"
                        ? "$25 Million"
                        : "₹208 Cr"}
                      . However, various market and other conditions may require
                      extensions of the raise periods at the sole discretion of
                      the General Partner.
                    </li>

                    <li>
                      <b>
                        <a href="#fund-note-1">Fund Note 2</a>: Portfolio Value
                        Revenue
                        <sup className="sup-cus">2</sup>{" "}
                      </b>{" "}
                      - The Total Portfolio Revenue (TPR) of{" "}
                      {user_redux.currency === "USD"
                        ? "$200 Million"
                        : "₹1543 Cr"}{" "}
                      is based on an anticipated and best possible estimated
                      value on a high level scope of number of units, average
                      unit sizes, and current estimated selling prices at the
                      time of modelling for each of these projects. These
                      numbers could vary depending on the market conditions,
                      price increases and sales offtake during the execution of
                      the project. The Portfolio indicates a Projected Portfolio
                      as anticipated by the Sponsor. Projects within the
                      Projected Portfolio may be replaced or removed at any time
                      prior to actual investment by the Fund in the sole
                      discretion of the Sponsor and the Total Portfolio Value
                      may be adjusted accordingly. Prospective Investors should
                      not rely on this information as a basis for an investment
                      in the Fund. The information presented here is based on
                      assumptions that may not be achieved.
                    </li>

                    <li>
                      <b>
                        <a href="#fund-note-1">Fund Note 3</a>: Minimum
                        Investment
                        <sup className="sup-cus">3</sup>{" "}
                      </b>{" "}
                      - The minimum investment amount is{" "}
                      {user_redux.currency === "USD" ? "$50,000" : "₹25,00,000"}
                      . This minimum is subject to reduction at the sole
                      discretion of the General Partner on a case by case basis.
                    </li>

                    <li>
                      <b>
                        <a href="#fund-note-1">Fund Note 4</a>: Phases and
                        Projects
                        <sup className="sup-cus">4</sup>{" "}
                      </b>{" "}
                      - The deployment phases are identified arbitrarily and the
                      projects identified under each are also arbitrary at this
                      time. This is the anticipated funds deployment timeline
                      and is subject to change at any time for any reason as the
                      fundraise and project finalization progresses. Funds may
                      be used by the Company immediately upon acceptance of any
                      such funds by the General Partner, the phase-based outline
                      set forth above is a general deployment plan only. All
                      information provided, including, but not limited to, the
                      Project, Revenue, Units, Saleable Area (sft)Project Cost,
                      Project Equity, and Selling Price(Mn./sft) are all
                      projected values based on an anticipated and best possible
                      estimated value on a high-level scope. The Portfolio
                      indicates a Projected Portfolio as anticipated by the
                      Sponsor. Projects within the Projected Portfolio may be
                      replaced or removed at any time prior to actual investment
                      by the Fund in the sole discretion of the Sponsor.
                      Prospective Investors should not rely on this information
                      as a basis for an investment in the Fund. The information
                      presented here is based on assumptions that may not be
                      achieved.
                    </li>

                    <li>
                      <b>
                        <a href="#offering">Fund Note 5</a>: Offering
                        Description
                        <sup className="sup-cus">5</sup>{" "}
                      </b>{" "}
                      - The above is a Projected Portfolio as anticipated by the
                      Alamo Group. Projects within the Portfolio may be replaced
                      or removed from the Portfolio at any time prior to actual
                      investment by the Fund in the sole discretion of the Alamo
                      Group. The above projects have real estate under contract
                      or currently being negotiated by special purpose entities
                      in which the Fund is considering investment, and for which
                      due diligence is currently ongoing. Prospective Investors
                      should not rely on this information as a basis for an
                      investment into the Fund. The information presented here
                      is based on assumptions that may not be achieved.
                    </li>
                  </ul>
                </p>
                {/* </Fade> */}
                {/* <p className="text-justify offering-desc mb-3"></p>
            <p className="text-justify offering-desc mb-0"></p> */}
              </div>
              <CallToAction />
              <Disclaimers />
              <CallToAction />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showInterest}
        onHide={() => setShowInterest(false)}
        size="lg"
      >
        <ModalHeader closeButton>
          <h5>Express Interest</h5>
        </ModalHeader>
        <ModalBody>
          <h6 className="mb-0">{listing?.title}</h6>
          <small className="text-secondary">
            Target IRR: {listing?.target_irr}% | Target Equity Multiple:{" "}
            {listing?.target_equity_multiple}
          </small>
          <p className="mt-3">
            Thank you for expressing your interest in this offering. Please
            provide the following details to keep you posted.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>Full Name</label>
                    <input
                      type="text"
                      placeholder="Name"
                      value={formik.values.name}
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.name &&
                        (formik.errors.name
                          ? "is-invalid"
                          : !formik.errors.name && "is-valid")
                      }`}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <p className="text-danger">{formik.errors.name}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      value={formik.values.email}
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.email &&
                        (formik.errors.email
                          ? "is-invalid"
                          : !formik.errors.email && "is-valid")
                      }`}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className="text-danger">{formik.errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>
                      How much would you like to invest in this project?
                    </label>
                    <input
                      type="text"
                      placeholder="Offering Amount($)"
                      value={formik.values.offering_amount}
                      name="offering_amount"
                      onChange={handleUpdateInput}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.offering_amount &&
                        (formik.errors.offering_amount
                          ? "is-invalid"
                          : !formik.errors.offering_amount && "is-valid")
                      }`}
                    />
                    {formik.errors.offering_amount &&
                      formik.touched.offering_amount && (
                        <p className="text-danger">
                          {formik.errors.offering_amount}
                        </p>
                      )}
                  </div>
                </div>

                <div className="col-md-12">
                  <button type="submit" className="btn btn-primary mb-4">
                    Sumbit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal show={showWebinar} onHide={() => setShowWebinar(false)} size="md">
        <ModalHeader closeButton>
          <h5>Register for Webinar</h5>
        </ModalHeader>
        <ModalBody>
          <h5 className="mb-0">{listing?.title}</h5>

          <div className="row">
            {listing?.webinars?.map((o, i) => (
              <div className="col-md-12">
                <div className="mt-3 border p-3 rounded">
                  <h6>{o.title}</h6>
                  <div>
                    <small className="text-secondary">{o.time}</small>
                  </div>
                  <div className="mt-2">
                    <a
                      href={o.link}
                      target="_blank"
                      className="btn btn-primary btn-sm"
                    >
                      Register Now
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const DocumentList = ({
  docIds = [],
  login = false,
  handleDownload = () => {},
}) => {
  const [list, setList] = useState([]);

  const getListDoc = async () => {
    try {
      // const result = await getMultiDocument(docIds);
      // setList(result.data.response);

      const result = st_documents.filter((o) => docIds.includes(o._id));
      setList(result);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getListDoc ~ error", error);
    }
  };

  useEffect(() => {
    docIds.length > 0 && getListDoc();
  }, [docIds.length]);

  return (
    <>
      {list?.map((o) =>
        login ? (
          <a
            href={o.document.url}
            download
            target="_blank"
            onClick={() => handleDownload(o)}
          >
            <FaRegFilePdf size={30} color={"#B30B00"} className="me-2" />
            {o.document.name.replace(".pdf", "")}
          </a>
        ) : (
          <a href={"/signin"}>
            <FaRegFilePdf size={30} color={"#B30B00"} className="me-2" />
            {o.document.name.replace(".pdf", "")}
          </a>
        )
      )}
    </>
  );
};

const DeckDocs = ({ input }) => {
  const user = useSelector((state) => state.user.data);

  const [listing, setListing] = useState();
  useEffect(() => {
    setListing(input);
  }, [input]);

  const handleDownload = async (input) => {
    try {
      await logDownloads(input._id, user.user_id);
    } catch (error) {
      console.log("🚀 ~ file: index.js:906 ~ handleDownload ~ error", error);
    }
  };

  return (
    <>
      {/* <div className="divide-section">
        <div className="docs">
          <h4>Fund Documents</h4>
          <hr />
          <DocumentList
            docIds={listing?.listing_documents}
            login={user.token ? true : false}
            handleDownload={handleDownload}
          />
        </div>
      </div> */}
      <div className="divide-section">
        <div className="docs">
          <h4>AXS Info Deck</h4>
          <hr />
          <DocumentList
            docIds={listing?.company_deck_documents}
            // login={user.token ? true : false}
            login
            handleDownload={handleDownload}
          />
        </div>
      </div>
      <div className="divide-section mb-0">
        <div className="docs">
          <h4>Project Info</h4>
          <hr />
          <ProjectsTableOnlyName
            usdValue={listing?.usd_value}
            onlyName
            projects={listing?.properties}
            // projects={[
            //   "638df2bde56a1b3c6896bc11",
            //   "638fff9ed4392a25dfa378bb",
            //   "639000aed4392a25dfa378d2",
            //   "63900143d4392a25dfa378e9",
            //   "638f9448dd0e1ed6c0c8737d",
            //   "6390040fd4392a25dfa3791e",
            // ]}
          />
        </div>
      </div>
      <div className="divide-section">
        <div className="docs">
          <h4>Project Portfolio </h4>
          <hr />
          <DocumentList
            docIds={listing?.portfolio_deck_documents}
            // login={user.token ? true : false}
            login
            handleDownload={handleDownload}
          />
        </div>
      </div>
    </>
  );
};

const SingleImage = ({ docIds = [] }) => {
  console.log("🚀 ~ SingleImage ~ docIds:", docIds);
  const [list, setList] = useState([]);

  const getListDoc = async () => {
    try {
      // const result =
      // await getMultiDocument(docIds);
      // setList(result.data.response);
      const result = st_documents.filter((o) => docIds.includes(o._id));
      // console.log(result, docIds);
      setList(result);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getListDoc ~ error", error);
    }
  };

  useEffect(() => {
    getListDoc();
  }, []);

  return (
    <>
      <img
        style={{
          height: "375px",
          objectFit: "cover",
          // objectPosition: "top",
        }}
        className="d-block w-100"
        src={list[0]?.document?.url}
        alt={list[0]?.document.name}
      />
    </>
  );
};

const ProjectsTableOnlyName = ({
  usdValue = 82,
  setProjectData = () => {},
  onlyName = false,
  projects = [],
}) => {
  const user_redux = useSelector((state) => state.user);

  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [prop, setProp] = useState();

  const [totals, setTotals] = useState({
    units: 0,
    project_cost: 0,
    project_debt: 0,
    project_equity: 0,
    inr_per_sqft: 0,
    targeted_investor_irr: 0,
    targeted_equity_multiple: 0,
    buildings: 0,
  });

  const getProjectList = async () => {
    try {
      // const result = await getMultiProperty(projects);
      const result = { data: { response: st_property } };

      var _list = [];

      for (var xx = 0; xx < projects.length; xx++) {
        _list.push(result.data.response.find((z) => z._id === projects[xx]));
      }

      setList(_list);

      getCalc(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getProjectList ~ error", error);
    }
  };

  const getCalc = (res_list) => {
    var _target_equity = 0,
      _target_portfolio = 0,
      _units = 0,
      _revenue = 0,
      _project_cost = 0,
      _project_debt = 0,
      _project_equity = 0,
      _inr_per_sqft = 0,
      _targeted_investor_irr = 0,
      _targeted_equity_multiple = 0,
      _acreage = 0,
      _buildings = 0;
    for (var x = 0; x < res_list.length; x++) {
      _target_portfolio += res_list[x].project_cost;
      _target_equity += res_list[x].project_equity;

      _units += res_list[x].units;
      _revenue += res_list[x].revenue;
      _project_cost += res_list[x].project_cost;
      _project_debt += res_list[x].project_debt;
      _project_equity += res_list[x].project_equity;
      _inr_per_sqft += res_list[x].inr_per_sqft;
      _targeted_investor_irr += res_list[x].targeted_investor_irr;
      _targeted_equity_multiple += res_list[x].targeted_equity_multiple;
      _acreage += res_list[x].acreage;
      _buildings += res_list[x].no_of_buildings;
    }

    setProjectData({
      target_raise: _target_equity,
      total_portfolio_value: _revenue,
    });

    setTotals({
      units: _units,
      revenue: _revenue,
      project_cost: _project_cost,
      project_debt: _project_debt,
      project_equity: _project_equity,
      inr_per_sqft: _inr_per_sqft / res_list.length,
      targeted_investor_irr: _targeted_investor_irr / res_list.length,
      targeted_equity_multiple: _targeted_equity_multiple / res_list.length,
      acreage: _acreage,
      buildings: _buildings,
    });
  };

  useEffect(() => {
    projects.length > 0 && getProjectList();
  }, [projects.length]);

  return (
    <>
      {onlyName ? (
        <table className="mb-4">
          <tbody>
            {list.map((o, i) => (
              <tr
                key={`row_proj_${i}`}
                role={"button"}
                onClick={() => {
                  setShow(true);
                  setProp(o);
                }}
              >
                <td className="pb-1">
                  <a href="javascript:;" className="svg-icon-height">
                    <BiBuildingHouse color={"#0c3c60"} className="me-2" />{" "}
                    {o.name}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="table table-hover mb-0">
          <thead>
            <tr className="theme-bg-table">
              <th className="text-center">S.No.</th>
              <th>Project Name</th>
              <th className="text-center">Units</th>
              <th className="text-center">Project Cost</th>
              <th className="text-center">Project Debt</th>
              <th className="text-center">Project Equity</th>
              <th className="text-center">$/Unit</th>
              <th className="text-center">Project Type</th>
              <th className="text-center">Buildings</th>
              <th className="text-start">Location</th>
              {/* <th className="text-start">Project Info</th> */}
            </tr>
          </thead>
          <tbody>
            {list.map((o, i) => (
              <tr
                key={`row_proj_${i}`}
                role={"button"}
                onClick={() => {
                  setShow(true);
                  setProp(o);
                }}
              >
                <td className="text-center">{i + 1}.</td>
                <td>
                  {o.is_deck_active === true && o.deck_link ? (
                    <a
                      href={o.deck_link}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {o.name}
                    </a>
                  ) : (
                    <>{o.name}</>
                  )}
                </td>
                <td className="text-center">{o.units}</td>
                <td className="text-center">
                  {numberFormatterTable(
                    o.project_cost,
                    user_redux.currency,
                    usdValue
                  )}
                </td>
                <td className="text-center">
                  {numberFormatterTable(
                    o.project_debt,
                    user_redux.currency,
                    usdValue
                  )}
                </td>
                <td className="text-center">
                  {numberFormatterTable(
                    o.project_equity,
                    user_redux.currency,
                    usdValue
                  )}
                </td>
                <td className="text-center">
                  {numberFormatterTable(
                    o.inr_per_sqft,
                    user_redux.currency,
                    usdValue
                  )}
                </td>
                <td className="text-center">{o.project_type}</td>
                <td className="text-center">{o.no_of_buildings}</td>
                {/* <td className="text-start">{`${o.city},${o.state}`}</td> */}
                <td className="text-start">
                  {o.google_pin_link ? (
                    <a
                      href={o.google_pin_link}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {`${o.city},${o.state}`}
                    </a>
                  ) : (
                    <>{`${o.city},${o.state}`}</>
                  )}
                </td>
              </tr>
            ))}
            <tr className="custom-bg-footer">
              <th></th>
              <th>
                <>Portfolio Totals</>
              </th>
              <th>
                {numberFormatterOnly(
                  totals.units,
                  user_redux.currency,
                  usdValue
                )}
              </th>
              <th className="text-center">
                {numberFormatter(
                  totals.project_cost,
                  user_redux.currency,
                  usdValue
                )}
              </th>
              <th className="text-center">
                {numberFormatter(
                  totals.project_debt,
                  user_redux.currency,
                  usdValue
                )}
              </th>
              <th className="text-center">
                {numberFormatter(
                  totals.project_equity,
                  user_redux.currency,
                  usdValue
                )}
              </th>
              <th className="text-center">
                {numberFormatter(
                  totals.inr_per_sqft,
                  user_redux.currency,
                  usdValue
                )}
              </th>
              <th className="text-center">Mixed Variety</th>
              <th className="text-center">{totals.buildings}</th>
              <th>Multiple TX</th>
              {/* <th>
                  {deck_link ? (
                    <a href={deck_link} target="_blank">
                      {deck_name}
                    </a>
                  ) : (
                    <>{deck_name}</>
                  )}
                </th> */}
            </tr>
          </tbody>
        </table>
      )}

      <Modal show={show} onHide={() => setShow(false)} size={"lg"}>
        <ModalHeader closeButton>
          <div className="container-fluid">
            <h5 className="mb-0 w-100">{prop?.name}</h5>
            <small className="">{prop?.address}</small>
          </div>
        </ModalHeader>
        <ModalBody>
          <ProjectImagesList docsIds={prop?.property_image} />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card-like">
                  <label>Revenue</label>
                  <div className="value">
                    {numberFormatter(
                      prop?.revenue,
                      user_redux.currency,
                      usdValue
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Selling Price (./sft)</label>
                  <div className="value">
                    {
                      numberFormatterSellingPrice(
                        prop?.inr_per_sqft,
                        user_redux.currency,
                        usdValue
                      ).split(".")[0]
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Equity</label>
                  <div className="value">
                    {numberFormatter(
                      prop?.project_equity,
                      user_redux.currency,
                      usdValue
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Cost</label>
                  <div className="value">
                    {numberFormatter(
                      prop?.project_cost,
                      user_redux.currency,
                      usdValue
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="col-md-3">
                <div className="card-like">
                  <label>Project Debt</label>
                  <div className="value">
                    {numberFormatter(
                      prop?.project_debt,
                      user_redux.currency,
                      usdValue
                    )}
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-3">
                <div className="card-like">
                  <label>Project Type</label>
                  <div className="value">{prop?.project_type}</div>
                </div>
              </div> */}
              <div className="col-md-3">
                <div className="card-like">
                  <label>Acreage</label>
                  <div className="value">{prop?.acreage}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Saleable Area (sft)</label>
                  <div className="value">
                    {numberCommaFormatter(prop?.sba_min_per_sqft)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Units</label>
                  <div className="value">{prop?.units}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Floors</label>
                  <div className="value">{prop?.no_of_floors}</div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card-like">
                  <label>Target IRR (%)</label>
                  <div className="value">{prop?.targeted_investor_irr}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Target Equity Multiple</label>
                  <div className="value">{prop?.targeted_equity_multiple}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Investor Returns</label>
                  <div className="value">
                    {numberFormatter(
                      prop?.equity_returns,
                      user_redux.currency,
                      usdValue
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Duration (Years)</label>
                  <div className="value">{prop?.project_timelines}</div>
                </div>
              </div>

              <div className="col-12 text-justify">
                <p className="help-text-modal ">
                  * All information contained herein are projected values based
                  on an anticipated and best possible estimated value on a
                  high-level scope. Prospective Investors should not rely on
                  this information as a basis for an investment in the Fund. The
                  information presented here is based on assumptions that may
                  not be achieved.
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ProjectsTable = ({
  usdValue = 82,
  setProjectData = () => {},
  onlyName = false,
  projects = [],
}) => {
  const user_redux = useSelector((state) => state.user);

  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [prop, setProp] = useState();

  const [totals, setTotals] = useState({
    units: 0,
    revenue: 0,
    project_cost: 0,
    project_debt: 0,
    project_equity: 0,
    inr_per_sqft: 0,
    targeted_investor_irr: 0,
    targeted_equity_multiple: 0,
    no_of_floors: 0,
    equity_returns: 0,
    acreage: 0,
    buildings: 0,
  });

  const getProjectList = async () => {
    try {
      // const result = await getMultiProperty(projects);
      const result = { data: { response: st_property } };

      var _list = [];

      for (var xx = 0; xx < projects.length; xx++) {
        _list.push(result.data.response.find((z) => z._id === projects[xx]));
      }

      setList(_list);
      console.log("🚀 ~ getProjectList ~ _list:", _list);
      getCalc(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getProjectList ~ error", error);
    }
  };

  const getCalc = (res_list) => {
    console.log("🚀 ~ file: index.js:1642 ~ getCalc ~ res_list", res_list);
    var _target_equity = 0,
      _target_portfolio = 0,
      _units = 0,
      _revenue = 0,
      _project_cost = 0,
      _project_debt = 0,
      _project_equity = 0,
      _inr_per_sqft = 0,
      _targeted_investor_irr = 0,
      _targeted_equity_multiple = 0,
      _equity_returns = 0,
      _no_of_floors = 0,
      _acreage = 0,
      _buildings = 0;
    for (var x = 0; x < res_list.length; x++) {
      _target_portfolio += res_list[x].project_cost;
      _target_equity += res_list[x].project_equity;

      _units += res_list[x].units;
      _revenue += res_list[x].revenue;
      _project_cost += res_list[x].project_cost;
      _project_debt += res_list[x].project_debt;
      _project_equity += res_list[x].project_equity;
      _inr_per_sqft += res_list[x].inr_per_sqft;
      _targeted_investor_irr += res_list[x].targeted_investor_irr;
      _targeted_equity_multiple += res_list[x].targeted_equity_multiple;
      _equity_returns += res_list[x].equity_returns;
      _no_of_floors += res_list[x].no_of_floors;
      _acreage += res_list[x].sba_min_per_sqft;
      _buildings += res_list[x].no_of_buildings;
    }

    setProjectData({
      target_raise: _target_equity,
      total_portfolio_value: _revenue,
    });

    setTotals({
      units: _units,
      revenue: _revenue,
      project_cost: _project_cost,
      project_debt: _project_debt,
      project_equity: _project_equity,
      inr_per_sqft: _inr_per_sqft / res_list.length,
      targeted_investor_irr: _targeted_investor_irr / res_list.length,
      targeted_equity_multiple: _targeted_equity_multiple / res_list.length,
      equity_returns: _equity_returns,
      no_of_floors: Math.round(_no_of_floors / res_list.length),
      acreage: _acreage,
      buildings: _buildings,
    });
  };

  useEffect(() => {
    projects.length > 0 && getProjectList();
  }, [projects.length]);

  return (
    <>
      <table className="table table-hover mb-0">
        <thead>
          <tr className="theme-bg-table">
            <th className="text-start">
              Project <br /> Name{" "}
              {/* <a href="#fund-notes" className="hyp-link">
                <sup className="sup-cus-low" style={{ color: "yellow" }}>
                  Fund Note 4
                </sup>
              </a> */}
            </th>
            <th className="text-center">
              {" "}
              No of <br /> Units
            </th>
            <th className="text-center">
              Area <br />
              (sft)
            </th>
            <th className="text-center">
              Selling Price <br />
              ($/sf)
            </th>
            <th className="text-center">
              Revenue <br /> (In Million)
            </th>
            {/* <th className="text-center">Project Cost</th> */}
            {/* <th className="text-center">Project Debt</th> */}
            <th className="text-center">
              Equity <br /> (In Million)
            </th>
            {/* <th className="text-center">
              Investor <br /> Returns
            </th> */}
            {/* <th className="text-center">Project Type</th> */}
            <th className="text-center">
              Target <br /> IRR %
            </th>
            <th className="text-center">
              Target Equity <br /> Multiple
            </th>
            <th className="text-center">
              No of <br /> Floors
            </th>

            {/* <th className="text-center">Buildings</th> */}
            <th className="text-center">
              Location <br /> India
            </th>
            {/* <th className="text-start">Project Info</th> */}
          </tr>
        </thead>
        <tbody>
          {list.map((o, i) => {
            const Actual = ({ space }) => {
              return (
                <tr
                  key={`row_proj_${i}`}
                  role={"button"}
                  style={
                    space && { borderTop: "20px solid rgb(238, 238, 238)" }
                  }
                  onClick={() => {
                    setShow(true);
                    setProp(o);
                  }}
                >
                  {/* <td className="text-center">{i + 1}.</td> */}
                  <td>
                    {o.is_deck_active === true && o.deck_link ? (
                      <a
                        href={o.deck_link}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {o.name}
                      </a>
                    ) : (
                      <>{o.name}</>
                    )}
                  </td>

                  <td className="text-center">{o.units}</td>
                  <td className="text-center">
                    {numberCommaTableFormatter(o.sba_min_per_sqft)}
                  </td>
                  <td className="text-center">
                    {/* {
                      currencyFormat(o.inr_per_sqft, "INR", usdValue).split(
                        "."
                      )[0]
                    } */}
                    {numberFormatterSellingPrice(
                      o.inr_per_sqft,
                      user_redux.currency,
                      usdValue
                    )}
                    /sf
                  </td>

                  <td className="text-center">
                    {numberFormatterTable(
                      o.revenue,
                      user_redux.currency,
                      usdValue
                    )}
                  </td>
                  {/* <td className="text-center">
                    {numberFormatter(
                      o.project_cost,
                      user_redux.currency,
                      usdValue
                    )}
                  </td> */}
                  {/* <td className="text-center">
                    {numberFormatter(
                      o.project_debt,
                      user_redux.currency,
                      usdValue
                    )}
                  </td> */}
                  <td className="text-center">
                    {numberFormatterTable(
                      o.project_equity,
                      user_redux.currency,
                      usdValue
                    )}
                  </td>
                  {/* <td className="text-center">
                    {numberFormatter(
                      o.equity_returns,
                      user_redux.currency,
                      usdValue
                    )}
                  </td> */}
                  {/* <td className="text-center">{o.project_type}</td> */}
                  <td className="text-center">{o.targeted_investor_irr} %</td>
                  <td className="text-center">
                    {o.targeted_equity_multiple.toFixed(2)}
                  </td>
                  <td className="text-center">{o.no_of_floors}</td>

                  {/* <td className="text-center">{o.no_of_buildings}</td> */}

                  {/* <td className="text-start">{`${o.city},${o.state}`}</td> */}
                  <td className="text-start">
                    {o.google_pin_link ? (
                      <a
                        href={o.google_pin_link}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {`${o.city}`}
                      </a>
                    ) : (
                      <>{`${o.city}`}</>
                    )}
                  </td>
                </tr>
              );
            };

            switch (i) {
              case 0:
                return (
                  <>
                    <Actual space />
                  </>
                );

              // case 2:
              //   return (
              //     <>
              //       <ProjectsTotalTable
              //         usdValue={usdValue}
              //         projects={[
              //           "638df2bde56a1b3c6896bc11",
              //           "638fff9ed4392a25dfa378bb",
              //         ]}
              //       />
              //       <tr className="phase-row">
              //         <td colSpan={11}>
              //           <div className="row align-items-center">
              //             <div className="col col-md-auto">
              //               <h6>
              //                 Deployment Phase 2: $40 Million Equity
              //                 <a href="#fund-notes" className="hyp-link">
              //                   <sup className="sup-cus-low">4</sup>
              //                 </a>
              //               </h6>
              //             </div>
              //             <div className="col-12 col-md-auto p-0">
              //               <small className="ms-3 ms-md-0">
              //                 (Anticipated Deployment from Jun 2023 to Aug 2023)
              //               </small>
              //             </div>
              //           </div>
              //           {/* <div className="text-md-center">
              //             <h6 className="mb-0">
              //               Phase 2: $40 Million Equity
              //             </h6>
              //             <small>(Raise from July 2023 to December 2023)</small>
              //           </div> */}
              //         </td>
              //       </tr>
              //       <Actual />
              //     </>
              //   );

              // case 4:
              //   return (
              //     <>
              //       <ProjectsTotalTable
              //         usdValue={usdValue}
              //         projects={[
              //           "639000aed4392a25dfa378d2",
              //           "63900143d4392a25dfa378e9",
              //         ]}
              //       />
              //       <tr className="phase-row">
              //         <td colSpan={9}>
              //           <div className="row align-items-center">
              //             <div className="col col-md-auto">
              //               <h6>
              //                 Deployment Phase 3: $50 Million Equity
              //                 <a href="#fund-notes" className="hyp-link">
              //                   <sup className="sup-cus-low">4</sup>
              //                 </a>
              //               </h6>
              //             </div>
              //             <div className="col-12 col-md-auto p-0">
              //               <small className="ms-3 ms-md-0">
              //                 (Anticipated Deployment from Sep 2023 to Dec 2023)
              //               </small>
              //             </div>
              //           </div>

              //           {/* <div className="text-md-center">
              //             <h6 className="mb-0">
              //               Phase 3: $50 Million Equity
              //             </h6>
              //             <small>(Raise from January 2024 to June 2024)</small>
              //           </div> */}
              //         </td>
              //       </tr>
              //       <Actual />
              //     </>
              //   );

              // case 5:
              //   return (
              //     <>
              //       <Actual />
              //       <ProjectsTotalTable
              //         usdValue={usdValue}
              //         projects={[
              //           "638f9448dd0e1ed6c0c8737d",
              //           "6390040fd4392a25dfa3791e",
              //         ]}
              //       />
              //     </>
              //   );

              default:
                return (
                  <>
                    <Actual />
                  </>
                );
            }
          })}
        </tbody>
        <tfoot>
          <tr
            className="theme-bg-table"
            style={{ borderTop: "20px solid #eee" }}
          >
            <th>
              <>
                Portfolio Totals{" "}
                {/* <a href="#fund-notes" className="hyp-link">
                  <sup className="sup-cus-low" style={{ color: "yellow" }}>
                    Fund Note 4
                  </sup>
                </a> */}
              </>
            </th>
            <th>{numberFormatterOnly(totals.units)}</th>
            <th className="text-center">
              {numberCommaFormatter(totals.acreage)}
            </th>
            <th className="text-center">
              {/* {
                currencyFormat(totals.inr_per_sqft, "INR", usdValue).split(
                  "."
                )[0]
              } */}
              {/* {numberFormatterSellingPrice(
                totals.inr_per_sqft,
                user_redux.currency,
                usdValue
              )} */}
              {user_redux.currency === "USD" ? "$80/sf" : "₹6,500"}
            </th>
            <th>
              {numberFormatter(totals.revenue, user_redux.currency, usdValue)}
            </th>
            {/* <th className="text-center">
              {numberFormatter(
                totals.project_cost,
                user_redux.currency,
                usdValue
              )}
            </th> */}
            {/* <th className="text-center">
              {numberFormatter(
                totals.project_debt,
                user_redux.currency,
                usdValue
              )}
            </th> */}
            <th className="text-center">
              {numberFormatter(
                totals.project_equity,
                user_redux.currency,
                usdValue
              )}
            </th>
            {/* <th>
              {numberFormatter(
                totals.equity_returns,
                user_redux.currency,
                usdValue
              )}
            </th> */}
            {/* <th className="text-center">Mixed Variety</th> */}
            <th className="text-center">{"23 %"}</th>
            <th className="text-center">
              {totals.targeted_equity_multiple.toFixed(2)}
            </th>
            <th className="text-center"> {totals.no_of_floors} </th>

            {/* <th className="text-center">{totals.buildings}</th> */}
            <th>Multiple</th>
            {/* <th>
                  {deck_link ? (
                    <a href={deck_link} target="_blank">
                      {deck_name}
                    </a>
                  ) : (
                    <>{deck_name}</>
                  )}
                </th> */}
          </tr>
        </tfoot>
      </table>

      <Modal show={show} onHide={() => setShow(false)} size={"lg"}>
        <ModalHeader closeButton>
          <div className="container-fluid">
            <h5 className="mb-0 w-100">{prop?.name}</h5>
            <small className="">{prop?.address}</small>
          </div>
        </ModalHeader>
        <ModalBody>
          <ProjectImagesList docsIds={prop?.property_image} />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card-like">
                  <label>Revenue</label>
                  <div className="value">
                    {numberFormatter(
                      prop?.revenue,
                      user_redux.currency,
                      usdValue
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Selling Price (./sft)</label>
                  <div className="value">
                    {
                      numberFormatterSellingPrice(
                        prop?.inr_per_sqft,
                        user_redux.currency,
                        usdValue
                      ).split(".")[0]
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Equity</label>
                  <div className="value">
                    {numberFormatter(
                      prop?.project_equity,
                      user_redux.currency,
                      usdValue
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Cost</label>
                  <div className="value">
                    {numberFormatter(
                      prop?.project_cost,
                      user_redux.currency,
                      usdValue
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="col-md-3">
                <div className="card-like">
                  <label>Project Debt</label>
                  <div className="value">
                    {numberFormatter(
                      prop?.project_debt,
                      user_redux.currency,
                      usdValue
                    )}
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-3">
                <div className="card-like">
                  <label>Project Type</label>
                  <div className="value">{prop?.project_type}</div>
                </div>
              </div> */}
              <div className="col-md-3">
                <div className="card-like">
                  <label>Acreage</label>
                  <div className="value">{prop?.acreage}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Saleable Area (sft)</label>
                  <div className="value">
                    {numberCommaFormatter(prop?.sba_min_per_sqft)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Units</label>
                  <div className="value">{prop?.units}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Floors</label>
                  <div className="value">{prop?.no_of_floors}</div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card-like">
                  <label>Target IRR (%)</label>
                  <div className="value">{prop?.targeted_investor_irr}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Target Equity Multiple</label>
                  <div className="value">{prop?.targeted_equity_multiple}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Investor Returns</label>
                  <div className="value">
                    {numberFormatter(
                      prop?.equity_returns,
                      user_redux.currency,
                      usdValue
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Duration (Years)</label>
                  <div className="value">{prop?.project_timelines}</div>
                </div>
              </div>

              <div className="col-12 text-justify">
                <p className="help-text-modal ">
                  * All information contained herein are projected values based
                  on an anticipated and best possible estimated value on a
                  high-level scope. Prospective Investors should not rely on
                  this information as a basis for an investment in the Fund. The
                  information presented here is based on assumptions that may
                  not be achieved.
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ProjectImagesList = ({ docsIds = [] }) => {
  return (
    <Carousel className="mb-3" interval={3000} fade>
      {docsIds.map((obj) => (
        <Carousel.Item>
          <SingleImage docIds={[obj]} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

function TriggerExample({ component, msg }) {
  const renderTooltip = (props) => <Tooltip {...props}>{msg}</Tooltip>;

  return (
    <OverlayTrigger placement="top" overlay={renderTooltip}>
      {component}
    </OverlayTrigger>
  );
}

export default NewOfferProperty;

import Logo from "../../../images/logo.png";
import Select from "react-select";
import { useState } from "react";
import { useFormik } from "formik";
import Footer from "../../footer";
import { FiInfo } from "react-icons/fi";
import { Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { responseToastMsg } from "../../../utils/response-message";
import { registerApi } from "../../../client/methods";
import { registerInvestSchema, registerSchema } from "../../../utils/schema";
import { crispStyle } from "../../../utils/common";

import InputPhone from "../../../components/input-phone";
import { useQuery } from "../../../hooks/url-params";
import "./style.scss";
import { registerGeneralSchema } from "./../../../utils/schema";

const Register = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState();
  const { search } = useLocation();
  const page_id = new URLSearchParams(search).get("page_id");

  console.log("TCL: page_id", page_id);

  const location = useLocation();
  const query = useQuery(location.search);
  const type_sign = query.get("type");

  const sign_up_type = type_sign ? type_sign : "general";

  const init = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    country_code: "",
    page_id: "",
    sign_up_type,
    expected_investment_now: "",
    expected_investment_next: "",
    expected_investment_now_value: "",
    expected_investment_now_value_non_ira: "",
    investing_account_type: "",
    self_accredited: "",
    investing_ira_fund: "",
    comments: "",
    create_new_password: "",
    create_confirm_password: "",
  };

  let validateSchema = "";
  if (sign_up_type === "interest") {
    validateSchema = registerSchema;
  } else if (sign_up_type === "invest") {
    validateSchema = registerInvestSchema;
  } else {
    validateSchema = registerGeneralSchema;
  }

  const investing_account_type_options = [
    {
      label: "Individual account",
      value: "0",
    },
    {
      label: "Joint account",
      value: "1",
    },
    {
      label: "Retirement 401k plan",
      value: "2",
    },
    {
      label: "IRA",
      value: "3",
    },
    {
      label: "Trust",
      value: "4",
    },
    {
      label: "Sole Proprietor",
      value: "5",
    },
    {
      label: "Multi-member LLC",
      value: "6",
    },
    {
      label: "Single-member LLC",
      value: "7",
    },
    {
      label: "Corporation",
      value: "8",
    },
    {
      label: "Partnership",
      value: "9",
    },
    {
      label: "Not sure. Need help",
      value: "10",
    },
  ];

  const yesNoOptions = [
    { label: "Yes", value: "2" },
    { label: "No", value: "1" },
    { label: "Not sure. Need help", value: "0" },
  ];

  const iraOptions = [
    { label: "Yes", value: "3" },
    { label: "No", value: "2" },
    { label: "May be", value: "1" },
    { label: "Not decided", value: "0" },
  ];

  const investment_now_options = [
    {
      label: "Undecided",
      value: "0",
    },
    {
      label: "$100,000 - $249,999",
      value: "1",
    },
    {
      label: "$250,000 - $449,999",
      value: "2",
    },
    {
      label: "$500,000 - $999,999",
      value: "3",
    },
  ];

  const investment_next_options = [
    {
      label: "Undecided",
      value: "0",
    },
    {
      label: "$100,000 - $249,999",
      value: "1",
    },
    {
      label: "$250,000 - $449,999",
      value: "2",
    },
    {
      label: "$500,000 - $999,999",
      value: "3",
    },
    {
      label: "$1,000,000+",
      value: "4",
    },
  ];

  const onSubmit = async (values) => {
    try {
      const result = await registerApi(values);

      responseToastMsg(result.data.response_code);
      navigate("/signin");
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log("🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error", error);
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: validateSchema,
    //  sign_up_type === "interest" ? registerSchema : registerInvestSchema,
    onSubmit,
  });

  return (
    <>
      {/* {console.log(formik.errors)} */}
      {/* <Header page="signup" /> */}
      <div className="login_wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="l_inner">
                <div className="text-center mb-4">
                  <img
                    style={{
                      background: "#0c3c60",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    src={Logo}
                    alt=""
                    width={130}
                    onClick={() => navigate("/")}
                  />
                </div>
                <h2>
                  {sign_up_type === "interest"
                    ? "Express Interest for"
                    : "Invest now in"}{" "}
                  Alamo India Fund
                </h2>
                <p className="sub-title"></p>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group mb-3">
                    <label>First Name</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      value={formik.values.first_name}
                      name="first_name"
                      onChange={formik.handleChange}
                      className={`form-control`}
                    />
                    {formik.errors.first_name && formik.touched.first_name && (
                      <p className="text-danger">{formik.errors.first_name}</p>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label>Last Name</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={formik.values.last_name}
                      name="last_name"
                      onChange={formik.handleChange}
                      className={`form-control`}
                    />
                    {formik.errors.last_name && formik.touched.last_name && (
                      <p className="text-danger">{formik.errors.last_name}</p>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label>Email Address</label>
                    <input
                      type="email"
                      placeholder="john@domain.com"
                      value={formik.values.email}
                      name="email"
                      onChange={formik.handleChange}
                      className={`form-control`}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className="text-danger">{formik.errors.email}</p>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label>Phone</label>
                    <InputPhone
                      className={`form-control`}
                      defaultCountry={"us"}
                      value={formik.values.phone_number}
                      onChange={(e, c_code) => {
                        formik.handleChange("phone_number")(e);
                        formik.handleChange("country_code")(
                          c_code.countryCode.toUpperCase()
                        );
                      }}
                    />

                    {formik.errors.phone_number &&
                      formik.touched.phone_number && (
                        <p className="text-danger">
                          {formik.errors.phone_number}
                        </p>
                      )}
                  </div>

                  {formik.values.sign_up_type === "general" ? (
                    <>
                      <div className="form-group mb-3">
                        <label>Are you an Accredited Investor?</label>
                        <FiInfo
                          className="ms-1"
                          type="button"
                          onClick={() => setShow(true)}
                        />
                        <Select
                          menuPosition={"fixed"}
                          name="self_accredited"
                          value={
                            formik.values?.self_accredited
                              ? yesNoOptions.find(
                                  (o) =>
                                    o.value === formik.values.self_accredited
                                )
                              : null
                          }
                          styles={crispStyle}
                          options={yesNoOptions}
                          onChange={(data) =>
                            formik.setFieldValue("self_accredited", data.value)
                          }
                        />
                        {formik.errors.self_accredited &&
                          formik.touched.self_accredited && (
                            <p className="text-danger">
                              {formik.errors.self_accredited}
                            </p>
                          )}
                      </div>
                      <div className="form-group mb-4">
                        <label>Password</label>
                        <input
                          type="password"
                          placeholder="Password"
                          value={formik.values.create_new_password}
                          name="create_new_password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control`}
                        />
                        {formik.errors.create_new_password &&
                          formik.touched.create_new_password && (
                            <p className="text-danger">
                              {formik.errors.create_new_password}
                            </p>
                          )}
                      </div>
                      <div className="form-group mb-4 password">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          value={formik.values.create_confirm_password}
                          name="create_confirm_password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control`}
                        />
                        {formik.errors.create_confirm_password &&
                          formik.touched.create_confirm_password && (
                            <p className="text-danger">
                              {formik.errors.create_confirm_password}
                            </p>
                          )}
                      </div>
                    </>
                  ) : (
                    <>
                      {formik.values.sign_up_type === "interest" ? (
                        <>
                          <div className="form-group mb-3">
                            <label>Expected Investment Now</label>
                            <Select
                              menuPosition={"fixed"}
                              value={
                                formik.values?.expected_investment_now
                                  ? investment_now_options.find(
                                      (o) =>
                                        o.value ===
                                        formik.values.expected_investment_now
                                    )
                                  : null
                              }
                              styles={crispStyle}
                              options={investment_now_options}
                              onChange={(data) =>
                                formik.setFieldValue(
                                  "expected_investment_now",
                                  data.value
                                )
                              }
                            />
                            {formik.errors.expected_investment_now &&
                              formik.touched.expected_investment_now && (
                                <p className="text-danger">
                                  {formik.errors.expected_investment_now}
                                </p>
                              )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="form-group mb-3">
                            <label>Investment Amount (Non-IRA)</label>
                            <div className="input-group">
                              <span className="input-group-text">$</span>
                              <input
                                type="text"
                                placeholder="1,000,000"
                                value={
                                  formik.values
                                    .expected_investment_now_value_non_ira
                                }
                                name="expected_investment_now_value_non_ira"
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    formik.handleChange(e);
                                  }
                                }}
                                className={`form-control`}
                              />
                            </div>

                            {formik.errors
                              .expected_investment_now_value_non_ira &&
                              formik.touched
                                .expected_investment_now_value_non_ira && (
                                <p className="text-danger">
                                  {
                                    formik.errors
                                      .expected_investment_now_value_non_ira
                                  }
                                </p>
                              )}
                          </div>
                          <div className="form-group mb-3">
                            <label>Investment Amount (IRA)</label>
                            <div className="input-group">
                              <span className="input-group-text">$</span>
                              <input
                                type="text"
                                placeholder="1,000,000"
                                value={
                                  formik.values.expected_investment_now_value
                                }
                                name="expected_investment_now_value"
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    formik.handleChange(e);
                                  }
                                }}
                                className={`form-control`}
                              />
                            </div>

                            {formik.errors.expected_investment_now_value &&
                              formik.touched.expected_investment_now_value && (
                                <p className="text-danger">
                                  {formik.errors.expected_investment_now_value}
                                </p>
                              )}
                          </div>
                        </>
                      )}

                      <div className="form-group mb-3">
                        <label>
                          Expected Total Investment in the next 12 months
                        </label>
                        <Select
                          menuPosition={"fixed"}
                          name="expected_investment_next"
                          value={
                            formik.values?.expected_investment_next
                              ? investment_next_options.find(
                                  (o) =>
                                    o.value ===
                                    formik.values.expected_investment_next
                                )
                              : null
                          }
                          styles={crispStyle}
                          options={investment_next_options}
                          onChange={(data) =>
                            formik.setFieldValue(
                              "expected_investment_next",
                              data.value
                            )
                          }
                        />
                        {formik.errors.expected_investment_next &&
                          formik.touched.expected_investment_next && (
                            <p className="text-danger">
                              {formik.errors.expected_investment_next}
                            </p>
                          )}
                      </div>
                      <div className="form-group mb-3">
                        <label>Investing Account Type</label>
                        <Select
                          menuPosition={"fixed"}
                          name="investing_account_type"
                          value={
                            formik.values?.investing_account_type
                              ? investing_account_type_options.find(
                                  (o) =>
                                    o.value ===
                                    formik.values.investing_account_type
                                )
                              : null
                          }
                          styles={crispStyle}
                          options={investing_account_type_options}
                          onChange={(data) =>
                            formik.setFieldValue(
                              "investing_account_type",
                              data.value
                            )
                          }
                        />
                        {formik.errors.investing_account_type &&
                          formik.touched.investing_account_type && (
                            <p className="text-danger">
                              {formik.errors.investing_account_type}
                            </p>
                          )}
                      </div>
                      <div className="form-group mb-3">
                        <label>Are you an Accredited Investor?</label>
                        <FiInfo
                          className="ms-1"
                          type="button"
                          onClick={() => setShow(true)}
                        />
                        <Select
                          menuPosition={"fixed"}
                          name="self_accredited"
                          value={
                            formik.values?.self_accredited
                              ? yesNoOptions.find(
                                  (o) =>
                                    o.value === formik.values.self_accredited
                                )
                              : null
                          }
                          styles={crispStyle}
                          options={yesNoOptions}
                          onChange={(data) =>
                            formik.setFieldValue("self_accredited", data.value)
                          }
                        />
                        {formik.errors.self_accredited &&
                          formik.touched.self_accredited && (
                            <p className="text-danger">
                              {formik.errors.self_accredited}
                            </p>
                          )}
                      </div>

                      {formik.values.sign_up_type === "interest" && (
                        <div className="form-group mb-3">
                          <label>Are you Investing with IRA Funds?</label>
                          <Select
                            menuPosition={"fixed"}
                            name="investing_ira_fund"
                            value={
                              formik.values?.investing_ira_fund
                                ? iraOptions.find(
                                    (o) =>
                                      o.value ===
                                      formik.values.investing_ira_fund
                                  )
                                : null
                            }
                            styles={crispStyle}
                            options={iraOptions}
                            onChange={(data) =>
                              formik.setFieldValue(
                                "investing_ira_fund",
                                data.value
                              )
                            }
                          />
                          {formik.errors.investing_ira_fund &&
                            formik.touched.investing_ira_fund && (
                              <p className="text-danger">
                                {formik.errors.investing_ira_fund}
                              </p>
                            )}
                        </div>
                      )}

                      <div className="form-group mb-3">
                        <label>Comments / Questions?</label>
                        <textarea
                          rows={4}
                          type="text"
                          placeholder="Type..."
                          style={{ minHeight: "4rem" }}
                          value={formik.values.comments}
                          name="comments"
                          onChange={formik.handleChange}
                          className={`form-control`}
                        />
                      </div>
                      {/* <div className="form-group mb-3 password">
                    <label>Enter Password</label>
                    <input
                      type="password"
                      placeholder="Password"
                      value={formik.values.password}
                      name="password"
                      onChange={formik.handleChange}
                      className={`form-control ${
                        formik.touched.password &&
                        (formik.errors.password
                          ? "is-invalid"
                          : !formik.errors.password && "is-valid")
                      }`}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <p className="text-danger">{formik.errors.password}</p>
                    )}
                  </div>
                  <div className="form-group mb-3 password">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      value={formik.values.c_password}
                      name="c_password"
                      onChange={formik.handleChange}
                      className={`form-control ${
                        formik.touched.c_password &&
                        (formik.errors.c_password
                          ? "is-invalid"
                          : !formik.errors.c_password && "is-valid")
                      }`}
                    />
                    {formik.errors.c_password && formik.touched.c_password && (
                      <p className="text-danger">{formik.errors.c_password}</p>
                    )}
                  </div> */}

                      {/* <div className="form-check term_det">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="accept"
                    />
                    <label className="form-check-label">
                      I have read and accept the{" "}
                      <Link to="terms">terms and conditions</Link>
                    </label>
                  </div> */}
                    </>
                  )}

                  <div className="text-center">
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
                <p className="mt-4 text-center">
                  {" "}
                  Already an investor?{" "}
                  <Link to="/signin">
                    <u>Sign In</u>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size={"lg"}
        backdrop={"static"}
        scrollable={true}
        style={{ backdropFilter: "blur(1px)" }}
      >
        <Modal.Header closeButton>
          <h4 className="mb-0">Accredited Investor</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="fs-little mb-3">
            The definition of “accredited investor” can be found in Regulation D
            promulgated under the Securities Act of 1933. There are several
            different ways to be qualified as an accredited investor, including
            but not limited to:
            <ul className="mt-3">
              <li>
                Individuals having a pre-tax income exceeding $200,000 annually
                ($300,000 per couple) with the expectation of that continuing in
                the next year.
              </li>{" "}
              <li>
                Individuals having a net worth of more than $1 million,
                excluding the value of the primary residence.
              </li>
              <li>
                Individuals holding certain professional certifications,
                designations, and licenses that are active and in good standing
                (Series 7, Series 82, Series 65 holders).
              </li>
              <li>
                Entities owning more than $5,000,000 in “investments” (as
                defined in the Investment Company Act of 1940, not formed for
                the specific purpose of acquiring the subject securities.
              </li>
              <li>Entities in which all equity owners are accredited.</li>
              <li>
                Trusts with total assets in excess of $5,000,000 not formed for
                the specific purpose of acquiring the subject securities whose
                purchase is directed by a “sophisticated person” as defined in
                Regulation D.
              </li>
              For the full definition of “accredited investor” please see{""}
              <a
                href="https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor"
                target="_blank"
              >
                {" "}
                17 CFR § 230.501(a).{" "}
              </a>
            </ul>
          </div>
          {/* <div className="text-end">
            <button
            className="btn btn-outline-secondary ps-3 pe-3 btn-sm me-2"
            onClick={() => setShow(false)}
            >
            Cancel
            </button>
            <button
            className="btn btn-dark btn-sm ps-3 pe-3"
            onClick={handleResetPassword}
            >
              Confirm
              </button>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;

import React, { useEffect, useState } from "react";
import Select from "react-select";
import Header from "../header";
import InputMask from "react-input-mask";
import Footer from "../footer";
import Project_1 from "../../images/project_1.jpg";
import { FaHandPointRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  createInvestingAccounts,
  getInvestingAccountsList,
  getListing,
} from "../../client/methods";

import { currencyFormat } from "../../utils/common";
import { toast } from "react-hot-toast";
import countries from "../../utils/countries.json";
import "react-tabs/style/react-tabs.css";
import "./style.scss";
import { accountSchema } from "../../utils/schema";
import { useFormik } from "formik";
import { responseToastMsg } from "../../utils/response-message";
import { useSelector } from "react-redux";

const Invest = () => {
  const navigate = useNavigate();
  const [listing, setListing] = useState();
  const [accList, setAccList] = useState([]);
  const user = useSelector((state) => state.user);
  const [stage, setStage] = useState(0);
  const [amount, setAmount] = useState(0);
  const [newForm, setNewForm] = useState(false);

  const init = {
    investor_id: user.data.user_id,
    legal_investing_account_name: "",
    ssn: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "USA",
  };

  const onSubmit = async (values) => {
    try {
      const result = await createInvestingAccounts(values);

      responseToastMsg(result.data.response_code);

      setNewForm(false);
      getAccListingDetails();
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log("🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error", error);
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: accountSchema,
    onSubmit,
  });

  const getListingDetails = async () => {
    try {
      const result = await getListing();
      setListing(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:20 ~ getListingDetails ~ error", error);
    }
  };

  const getAccListingDetails = async () => {
    try {
      const result = await getInvestingAccountsList(
        user?.data?.user_id,
        100,
        0
      );
      setAccList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:20 ~ getListingDetails ~ error", error);
    }
  };

  useEffect(() => {
    getListingDetails();
    getAccListingDetails();
  }, []);

  const handleNext = () => {
    if (stage === 0) {
      if (
        parseInt(amount) > parseInt(listing.minimum_investment) &&
        parseInt(amount) < parseInt(listing.maximum_investment)
      ) {
        setStage(stage + 1);
      } else {
        toast.error(
          `Offer amount should be greater than ${currencyFormat(
            listing.minimum_investment
          )} and lesser than ${currencyFormat(listing.maximum_investment)}`
        );
      }
    } else if (stage === 1) {
      if (accList.find((o) => o.selected === true)) {
        setStage(stage + 1);
      } else {
        toast.error(`Choose any one account`);
      }
    }
  };

  const handlePrev = () => {
    setStage(stage - 1);
  };

  const handleUpdateInput = (e, check_number = true) => {
    if (check_number) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setAmount(e.target.value);
      }
    } else {
      setAmount(e.target.value);
    }
  };

  const handleSelect = (input) => {
    const info = [];

    for (let x = 0; x < accList.length; x++) {
      if (accList[x]._id === input._id) {
        info.push({
          ...accList[x],
          selected: true,
        });
      } else {
        info.push({
          ...accList[x],
          selected: false,
        });
      }
    }

    setAccList(info);
  };

  return (
    <>
      <Header />

      <div className="invest_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="inv_det">
                <Tabs selectedIndex={stage}>
                  <TabList>
                    <Tab disabled>
                      <span>1</span> Offer{" "}
                    </Tab>
                    <Tab>
                      {" "}
                      <span>2</span>Account
                    </Tab>
                    <Tab disabled>
                      <span>3</span>Documents{" "}
                    </Tab>
                    <Tab disabled>
                      <span>4</span>Funding{" "}
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <div className="tab_cnt">
                      <div className="offer_box">
                        <div className="form-group">
                          <label>Please enter an offer amount</label>
                          <div className="price_bx">
                            <input
                              type="text"
                              value={amount}
                              className="form-control mt-2"
                              onChange={(e) =>
                                handleUpdateInput(e, "amount", true)
                              }
                            />
                            <span>$</span>
                          </div>
                          <ul>
                            <li>
                              {" "}
                              <FaHandPointRight /> Minimum investment amount:
                              {currencyFormat(listing?.minimum_investment)}
                            </li>
                            <li>
                              <FaHandPointRight />
                              Maximum investment amount:{" "}
                              {currencyFormat(listing?.maximum_investment)}
                            </li>
                            {/* <li>
                              <FaHandPointRight />
                              Investment increment: $5,000
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      {/* <div className="acpt_det">
                        <p> By clicking the submit button below </p>
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Check1"
                          />
                          <label className="form-check-label">
                            I have read Overview Disclosures & Disclaimers (
                            <a href="">Click Here to Read)</a>
                          </label>
                        </div>
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Check2"
                          />
                          <label className="form-check-label">
                            I acknowledge that I am providing the project
                            sponsor an indication of my interest to invest in
                            this offering based on my review of the materials
                            made available to me.
                          </label>
                        </div>
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Check3"
                          />
                          <label className="form-check-label">
                            I have read the foregoing explanation and disclaimer
                            and understand its contents, including that I will
                            be asked to review, complete, execute and deliver
                            additional materials, including a subscription
                            agreement, operating agreement, and private
                            placement memorandum before my commitment to invest
                            will be complete, and no offer will be binding until
                            it is accepted in its sole and absolute discretion
                            by the project sponsor.
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="container-fluid">
                      {!newForm && (
                        <>
                          {accList.length > 0 && (
                            <>
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <td>Select</td>
                                    <td>Legal Investing Name</td>
                                    <td>SSN</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {accList.map((xx, i) => (
                                    <tr
                                      key={`row_acc${i}`}
                                      role={"button"}
                                      onClick={() => handleSelect(xx)}
                                    >
                                      <td>
                                        <input
                                          type="checkbox"
                                          checked={xx.selected}
                                        />
                                      </td>
                                      <td>{xx.legal_investing_account_name}</td>
                                      <td>{xx.ssn}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          )}

                          <div className="text-center">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => setNewForm(true)}
                            >
                              Add New Account
                            </button>
                          </div>
                        </>
                      )}

                      {newForm && (
                        <form onSubmit={formik.handleSubmit}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-2">
                                <label>Legal Investing Account Name</label>
                                <input
                                  type="text"
                                  placeholder="Legal Investing Account Name"
                                  value={
                                    formik.values.legal_investing_account_name
                                  }
                                  name="legal_investing_account_name"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={`form-control ${
                                    formik.touched
                                      .legal_investing_account_name &&
                                    (formik.errors.legal_investing_account_name
                                      ? "is-invalid"
                                      : !formik.errors
                                          .legal_investing_account_name &&
                                        "is-valid")
                                  }`}
                                />
                                {formik.errors.legal_investing_account_name &&
                                  formik.touched
                                    .legal_investing_account_name && (
                                    <p className="text-danger">
                                      {
                                        formik.errors
                                          .legal_investing_account_name
                                      }
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-2">
                                <label>SSN</label>
                                <InputMask
                                  mask="999-99-9999"
                                  value={formik.values.ssn}
                                  name="ssn"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={`form-control ${
                                    formik.touched.ssn &&
                                    (formik.errors.ssn
                                      ? "is-invalid"
                                      : !formik.errors.ssn && "is-valid")
                                  }`}
                                />

                                {formik.errors.ssn && formik.touched.ssn && (
                                  <p className="text-danger">
                                    {formik.errors.ssn}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-2">
                                <label>Address</label>
                                <input
                                  type="text"
                                  placeholder="Address"
                                  value={formik.values.address}
                                  name="address"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={`form-control ${
                                    formik.touched.address &&
                                    (formik.errors.address
                                      ? "is-invalid"
                                      : !formik.errors.address && "is-valid")
                                  }`}
                                />
                                {formik.errors.address &&
                                  formik.touched.address && (
                                    <p className="text-danger">
                                      {formik.errors.address}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-2">
                                <label>City</label>
                                <input
                                  type="text"
                                  placeholder="City"
                                  value={formik.values.city}
                                  name="city"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={`form-control ${
                                    formik.touched.city &&
                                    (formik.errors.city
                                      ? "is-invalid"
                                      : !formik.errors.city && "is-valid")
                                  }`}
                                />
                                {formik.errors.city && formik.touched.city && (
                                  <p className="text-danger">
                                    {formik.errors.city}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-2">
                                <label>State</label>
                                <input
                                  type="text"
                                  placeholder="State"
                                  value={formik.values.state}
                                  name="state"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={`form-control ${
                                    formik.touched.state &&
                                    (formik.errors.state
                                      ? "is-invalid"
                                      : !formik.errors.state && "is-valid")
                                  }`}
                                />
                                {formik.errors.state &&
                                  formik.touched.state && (
                                    <p className="text-danger">
                                      {formik.errors.state}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-2">
                                <label>Country</label>
                                <Select
                                  menuPosition={"fixed"}
                                  value={
                                    formik.values?.country
                                      ? countries
                                          .map((o) => ({
                                            label: o.name,
                                            value: o.code3,
                                          }))
                                          .find(
                                            (o) =>
                                              o.value === formik.values?.country
                                          )
                                      : null
                                  }
                                  //   styles={crispStyle}
                                  options={countries.map((o) => ({
                                    label: o.name,
                                    value: o.code3,
                                  }))}
                                  onChange={(data) =>
                                    formik.handleChange("country")(data.value)
                                  }
                                />
                                {formik.errors.country &&
                                  formik.touched.country && (
                                    <p className="text-danger">
                                      {formik.errors.country}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-2">
                                <label>Zipcode</label>
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  value={formik.values.zip}
                                  name="zip"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={`form-control ${
                                    formik.touched.zip &&
                                    (formik.errors.zip
                                      ? "is-invalid"
                                      : !formik.errors.zip && "is-valid")
                                  }`}
                                />
                                {formik.errors.zip && formik.touched.zip && (
                                  <p className="text-danger">
                                    {formik.errors.zip}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 text-center">
                              <button
                                type="button"
                                className="btn btn-secondary mt-3 me-3"
                                onClick={() => setNewForm(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary mt-3"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <h2>Any content 3</h2>
                  </TabPanel>
                  <TabPanel>
                    <h2>Any content 4</h2>
                  </TabPanel>
                </Tabs>

                <div className="nav_wrapper mt-5 mb-4">
                  <div className="nav_btn">
                    <button
                      className="btn btn-secondary m-0"
                      type="button"
                      onClick={() => navigate("/")}
                    >
                      Cancel
                    </button>
                    <div>
                      {stage > 0 && (
                        <button
                          className="btn btn-primary m-0 me-2"
                          type="button"
                          onClick={handlePrev}
                        >
                          Prev
                        </button>
                      )}
                      {stage < 3 && (
                        <button
                          className="btn btn-primary m-0"
                          type="button"
                          onClick={handleNext}
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="pr_short">
                <Link to="/single-property">
                  <img src={Project_1} alt="Property" />
                  <h4>Haven Huntley Meadows</h4>
                </Link>
                <ul>
                  <li>
                    <span>Target Investor IRR</span>
                    <span>{listing?.target_irr}</span>
                  </li>
                  <li>
                    <span>Targeted Investment Period</span>
                    <span>{listing?.targeted_investment_period}</span>
                  </li>
                  <li>
                    <span>Minimum Investment</span>
                    <span>{currencyFormat(listing?.minimum_investment)}</span>
                  </li>
                </ul>
                <div className="m_btn mb-3 text-center">
                  {/* <button className="btn btn-primary  m-0">Need Help?</button> */}
                  <b>Need Help?</b>
                  <p>Please reach us at support@alamoequity.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Invest;

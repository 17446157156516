import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import Logo from "../../images/logo.png";
import Carousel from "react-bootstrap/Carousel";
import Money from "../../images/skyline.png";
import Loan from "../../images/loan.png";
import Target from "../../images/target.png";
import { FaRegFilePdf } from "react-icons/fa";
import { BiBuildingHouse } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  createInterest,
  getListing,
  getListingByPageId,
  getMultiDocument,
  getMultiProperty,
} from "../../client/methods";

import "./style.scss";
import toast from "react-hot-toast";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import {
  currencyFormat,
  numberFormatter,
  numberFormatterOnly,
} from "../../utils/common";
import { useFormik } from "formik";
import { interestSchema } from "../../utils/schema";
import { responseToastMsg } from "../../utils/response-message";
import { useSelector } from "react-redux";
import People from "../../components/people/index";
import Disclaimers from "../../components/disclaimers";
import CallToAction from "../../components/call-to-action";

const NewOfferPropertyV1 = () => {
  const { page_id } = useParams();

  const navigate = useNavigate();

  const user = useSelector((state) => state.user.data);
  const [listing, setListing] = useState();
  const [showInterest, setShowInterest] = useState(false);
  const [showWebinar, setShowWebinar] = useState(false);

  const [projectData, setProjectData] = useState({
    target_raise: 0,
    total_portfolio_value: 0,
  });

  const getListingDetails = async () => {
    try {
      var result;
      if (page_id) {
        result = await getListingByPageId(page_id);
      } else {
        result = await getListingByPageId("default-id");
      }
      setListing(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:20 ~ getListingDetails ~ error", error);
    }
  };

  useEffect(() => {
    getListingDetails();
  }, []);

  const handleInvest = () => {
    window.open("/signup?type=invest&page_id=" + listing?.page_id, "_blank");
  };

  const init = {
    name: "",
    email: "",
    listing_id: "",
    offering_amount: "",
  };

  const onSubmit = async (values) => {
    try {
      await createInterest({ ...values, listing_id: listing?._id });

      toast.success(
        "We appreciate your interest in the project. We advise signing up for the webinar also."
      );
      setShowInterest(false);
      formik.resetForm();
    } catch (error) {
      responseToastMsg(error.data.error_code);
      console.log("🚀 ~ file: index.js ~ line 59 ~ handleSave ~ error", error);
    }
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: interestSchema,
    onSubmit,
  });

  const handleUpdateInput = (e, check_number = true) => {
    if (check_number) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        formik.setFieldValue("offering_amount", e.target.value);
      }
    } else {
      formik.setFieldValue("offering_amount", e.target.value);
    }
  };

  const handleShowInterest = () => {
    window.open("/signup?type=interest&page_id=" + listing?.page_id, "_blank");
    // setShowInterest(true);
    // console.log(user);
    // user &&
    //   formik.setValues({
    //     ...formik.values,
    //     name: user.name,
    //     email: user.email,
    //   });
  };

  return (
    <>
      <div className="property_wrapper">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="p_header">
                <div className="logo">
                  <Link to="/">
                    <img src={Logo} alt="" width={150} />
                  </Link>
                </div>
                <div className="">
                  <div className="text-header-title text-center">
                    Alamo Multifamily Group (AMG) Offering
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Fade bottom>
            <div className="row s_header align-items-center justify-content-center">
              <div className="col-12 order-md-2">
                <p className="h_text">NEWEST OFFERING</p>
                <h2>{listing?.title}</h2>
              </div>
              <div className="col-12 order-md-1 s_btn mb-2">
                <button
                  className="btn btn-outline-primary me-2 mb-2"
                  type="button"
                  onClick={handleShowInterest}
                >
                  Express Interest
                </button>
                <button
                  className="btn btn-outline-primary me-2 mb-2"
                  type="button"
                  onClick={() => setShowWebinar(true)}
                >
                  Register for Webinar
                </button>
                <button
                  className="btn btn-primary me-2 mb-2"
                  style={{ background: "#0d6efd !important" }}
                  type="button"
                  onClick={handleInvest}
                >
                  Invest Now
                </button>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="row s_banner">
              <div className="col-12">
                {/* <ProjectImages projects={listing?.properties} /> */}
                <ProjectImagesList docsIds={listing?.listing_images} />
              </div>
            </div>
          </Fade>
          {/* <div className="row s_banner">
            <div className="col-12">
              <div className="sbanner_inr">
                <img src={Property} alt="Propery"></img>
                <div className="cnt">
                  <h2>Secure Your Spot Today</h2>
                  <p>More Details Coming Soon.</p>
                </div>
              </div>
            </div>
          </div> */}

          <Fade bottom cascade>
            <div className="row s_feature">
              <div className="col-md-4">
                <div className="f_sec">
                  <div className="icon">
                    <img src={Target} alt="Exit Icon" />
                  </div>
                  <div className="cont">
                    <h3>Target Equity Raise</h3>
                    <h4>
                      {currencyFormat(projectData?.target_raise).split(".")[0]}
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="f_sec">
                  <div className="icon">
                    <img src={Money} alt="Exit Icon" />
                  </div>
                  <div className="cont">
                    <h3>Total Portfolio Value</h3>
                    <h4>
                      {
                        currencyFormat(
                          projectData?.total_portfolio_value
                        ).split(".")[0]
                      }
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="f_sec">
                  <div className="icon">
                    <img src={Loan} alt="Exit Icon" />
                  </div>
                  <div className="cont">
                    <h3>Minimum Investment</h3>
                    <h4>
                      {
                        currencyFormat(listing?.minimum_investment).split(
                          "."
                        )[0]
                      }
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Fade>

          <Fade bottom>
            <div className="table table-responsive">
              <ProjectsTable
                setProjectData={setProjectData}
                projects={listing?.properties}
                total_cost={listing?.estimated_total_cost}
                total_equity={listing?.total_equity}
                total_debts={listing?.total_debts}
                deck_link={listing?.deck_link}
                deck_name={listing?.deck_name}
                total_units={listing?.total_units}
                total_dollar_per_unit={listing?.total_dollar_per_unit}
                total_no_of_buildings={listing?.total_no_of_buildings}
              />
            </div>
          </Fade>

          <div className="row s_more">
            <Fade bottom>
              <div className="col-md-4 mb-md-0 mb-4  order-md-2">
                <div className="h_section">
                  <div className="divide-section">
                    <h4>Offering Highlights</h4>
                    <hr />
                    <p>
                      Target Investor IRR: <b>{listing?.target_irr}</b>
                    </p>
                    <p>
                      Target Equity Multiple:{" "}
                      <b>{listing?.target_equity_multiple}</b>
                    </p>{" "}
                    <p>
                      Targeted Investment Period:{" "}
                      <b>{listing?.targeted_investment_period}</b>
                    </p>
                  </div>
                  <div className="d-none d-md-block">
                    <DeckDocs input={listing} />
                  </div>
                </div>
              </div>
            </Fade>

            <div className="col-md-8 order-md-1">
              <div className="mr_over">
                <Fade bottom cascade>
                  <p className="d-flex wrap-custom">
                    <button
                      className="btn btn-primary me-2 mb-2 w-100 text-nowrap btn-primary-theme "
                      type="button"
                      onClick={handleShowInterest}
                    >
                      Express Interest
                    </button>
                    <button
                      className="btn btn-primary me-2 mb-2 w-100 text-nowrap btn-primary-theme "
                      type="button"
                      onClick={() => setShowWebinar(true)}
                    >
                      Register for Webinar
                    </button>
                    <button
                      className="btn btn-primary mb-2 w-100 text-nowrap btn-primary-theme "
                      type="button"
                      onClick={handleInvest}
                    >
                      Invest Now
                    </button>
                  </p>
                </Fade>
                <Fade bottom>
                  <h4>Offering Description</h4>
                </Fade>{" "}
                <Fade bottom cascade>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: listing?.offering_description,
                    }}
                  />
                </Fade>
              </div>
              <div className="h_section d-md-none">
                <Fade bottom cascade>
                  <DeckDocs input={listing} />
                </Fade>
              </div>
            </div>
          </div>
          <div className="row s_more">
            <div className="col">
              <People />
              <CallToAction />
              <Disclaimers />
              <CallToAction />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showInterest}
        onHide={() => setShowInterest(false)}
        size="lg"
      >
        <ModalHeader closeButton>
          <h5>Express Interest</h5>
        </ModalHeader>
        <ModalBody>
          <h6 className="mb-0">{listing?.title}</h6>
          <small className="text-secondary">
            Target IRR: {listing?.target_irr}% | Target Equity Multiple:{" "}
            {listing?.target_equity_multiple}
          </small>
          <p className="mt-3">
            Thank you for expressing your interest in this offering. Please
            provide the following details to keep you posted.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>Full Name</label>
                    <input
                      type="text"
                      placeholder="Name"
                      value={formik.values.name}
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.name &&
                        (formik.errors.name
                          ? "is-invalid"
                          : !formik.errors.name && "is-valid")
                      }`}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <p className="text-danger">{formik.errors.name}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      value={formik.values.email}
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.email &&
                        (formik.errors.email
                          ? "is-invalid"
                          : !formik.errors.email && "is-valid")
                      }`}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className="text-danger">{formik.errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>
                      How much would you like to invest in this project?
                    </label>
                    <input
                      type="text"
                      placeholder="Offering Amount($)"
                      value={formik.values.offering_amount}
                      name="offering_amount"
                      onChange={handleUpdateInput}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.offering_amount &&
                        (formik.errors.offering_amount
                          ? "is-invalid"
                          : !formik.errors.offering_amount && "is-valid")
                      }`}
                    />
                    {formik.errors.offering_amount &&
                      formik.touched.offering_amount && (
                        <p className="text-danger">
                          {formik.errors.offering_amount}
                        </p>
                      )}
                  </div>
                </div>

                <div className="col-md-12">
                  <button type="submit" className="btn btn-primary mb-4">
                    Sumbit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal show={showWebinar} onHide={() => setShowWebinar(false)} size="md">
        <ModalHeader closeButton>
          <h5>Register for Webinar</h5>
        </ModalHeader>
        <ModalBody>
          <h5 className="mb-0">{listing?.title}</h5>

          <div className="row">
            {listing?.webinars?.map((o, i) => (
              <div className="col-md-12">
                <div className="mt-3 border p-3 rounded">
                  <h6>{o.title}</h6>
                  <div>
                    <small className="text-secondary">{o.time}</small>
                  </div>
                  <div className="mt-2">
                    <a
                      href={o.link}
                      target="_blank"
                      className="btn btn-primary btn-sm"
                    >
                      Register Now
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const DocumentList = ({ docIds = [] }) => {
  const [list, setList] = useState([]);

  const getListDoc = async () => {
    try {
      const result = await getMultiDocument(docIds);
      setList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getListDoc ~ error", error);
    }
  };

  useEffect(() => {
    docIds.length > 0 && getListDoc();
  }, [docIds.length]);

  return (
    <>
      {list?.map((o) => (
        <a href={o.document.url} download target="_blank">
          <FaRegFilePdf size={30} color={"#B30B00"} className="me-2" />
          {o.document.name.replace(".pdf", "")}
        </a>
      ))}
    </>
  );
};

const DeckDocs = ({ input }) => {
  const [listing, setListing] = useState();
  useEffect(() => {
    setListing(input);
  }, [input]);
  return (
    <>
      <div className="divide-section">
        <div className="docs">
          <h4>Fund Documents</h4>
          <hr />
          <DocumentList docIds={listing?.listing_documents} />
        </div>
      </div>
      <div className="divide-section">
        <div className="docs">
          <h4>AMG Information</h4>
          <hr />
          <DocumentList docIds={listing?.company_deck_documents} />
        </div>
      </div>
      <div className="divide-section">
        <div className="docs">
          <h4>Portfolio Deck</h4>
          <hr />
          <DocumentList docIds={listing?.portfolio_deck_documents} />
        </div>
      </div>
      <div className="divide-section mb-0">
        <div className="docs">
          <h4>Individual Project Decks</h4>
          <hr />
          <ProjectsTable
            onlyName
            projects={listing?.properties}
            total_cost={listing?.estimated_total_cost}
            total_equity={listing?.total_equity}
            total_debts={listing?.total_debts}
            deck_link={listing?.deck_link}
            deck_name={listing?.deck_name}
            total_units={listing?.total_units}
            total_dollar_per_unit={listing?.total_dollar_per_unit}
            total_no_of_buildings={listing?.total_no_of_buildings}
          />
        </div>
      </div>
    </>
  );
};

const SingleImage = ({ docIds = [] }) => {
  const [list, setList] = useState([]);

  const getListDoc = async () => {
    try {
      const result = await getMultiDocument(docIds);
      setList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getListDoc ~ error", error);
    }
  };

  useEffect(() => {
    getListDoc();
  }, []);

  return (
    <>
      <img
        style={{
          height: "350px",
          objectFit: "cover",
          objectPosition: "top",
        }}
        className="d-block w-100"
        src={list[0]?.document?.url}
        alt={list[0]?.document.name}
      />
    </>
  );
};

const ProjectImages = ({ projects = [] }) => {
  const [list, setList] = useState([]);

  const getListDoc = async () => {
    try {
      const result = await getMultiProperty(projects);
      setList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getListDoc ~ error", error);
    }
  };

  useEffect(() => {
    projects.length > 0 && getListDoc();
  }, [projects]);

  return (
    <Carousel>
      {list.map((obj) => (
        <Carousel.Item>
          <SingleImage docIds={[obj?.property_image[0]]} />
          <Carousel.Caption>
            <h3 className="txt-shadow">{obj.name}</h3>
            <p className="txt-shadow">{obj.address}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

const ProjectsTable = ({
  setProjectData = () => {},
  onlyName = false,
  projects = [],
  total_cost = "",
  total_equity = "",
  total_debts = "",
  deck_name = "",
  deck_link = "",
  total_units = "",
  total_dollar_per_unit = "",
  total_no_of_buildings = "",
}) => {
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [prop, setProp] = useState();

  const [totals, setTotals] = useState({
    units: 0,
    project_cost: 0,
    project_debt: 0,
    project_equity: 0,
    dollar_per_unit: 0,
    buildings: 0,
  });

  const getProjectList = async () => {
    try {
      const result = await getMultiProperty(projects);
      setList(result.data.response);
      getCalc(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getProjectList ~ error", error);
    }
  };

  const getCalc = (res_list) => {
    var _target_equity = 0,
      _target_portfolio = 0,
      _units = 0,
      _project_cost = 0,
      _project_debt = 0,
      _project_equity = 0,
      _dollar_per_unit = 0,
      _buildings = 0;
    for (var x = 0; x < res_list.length; x++) {
      _target_portfolio += res_list[x].project_cost;
      _target_equity += res_list[x].project_equity;

      _units += res_list[x].units;
      _project_cost += res_list[x].project_cost;
      _project_debt += res_list[x].project_debt;
      _project_equity += res_list[x].project_equity;
      _dollar_per_unit += res_list[x].dollar_per_unit;
      _buildings += res_list[x].no_of_buildings;
    }

    setProjectData({
      target_raise: _target_equity,
      total_portfolio_value: _target_portfolio,
    });

    setTotals({
      units: _units,
      project_cost: _project_cost,
      project_debt: _project_debt,
      project_equity: _project_equity,
      dollar_per_unit: _dollar_per_unit / res_list.length,
      buildings: _buildings,
    });
  };

  useEffect(() => {
    projects.length > 0 && getProjectList();
  }, [projects]);

  return (
    <>
      {onlyName ? (
        <table className="mb-4">
          <tbody>
            {list.map((o, i) => (
              <tr
                key={`row_proj_${i}`}
                role={"button"}
                onClick={() => {
                  setShow(true);
                  setProp(o);
                }}
              >
                <td className="pb-1">
                  <a href="javascript:;" className="svg-icon-height">
                    <BiBuildingHouse color={"#0c3c60"} className="me-2" />{" "}
                    {o.name}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="table table-hover mb-4">
          <thead>
            <tr className="theme-bg-table">
              <th className="text-center">S.No.</th>
              <th>Project Name</th>
              <th className="text-center">Units</th>
              <th className="text-center">Project Cost</th>
              <th className="text-center">Project Debt</th>
              <th className="text-center">Project Equity</th>
              <th className="text-center">$/Unit</th>
              <th className="text-center">Project Type</th>
              <th className="text-center">Buildings</th>
              <th className="text-start">Location</th>
              {/* <th className="text-start">Project Info</th> */}
            </tr>
          </thead>
          <tbody>
            {list.map((o, i) => (
              <tr
                key={`row_proj_${i}`}
                role={"button"}
                onClick={() => {
                  setShow(true);
                  setProp(o);
                }}
              >
                <td className="text-center">{i + 1}.</td>
                <td>
                  {o.is_deck_active === true && o.deck_link ? (
                    <a
                      href={o.deck_link}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {o.name}
                    </a>
                  ) : (
                    <>{o.name}</>
                  )}
                </td>
                <td className="text-center">{o.units}</td>
                <td className="text-center">
                  {numberFormatter(o.project_cost)}
                </td>
                <td className="text-center">
                  {numberFormatter(o.project_debt)}
                </td>
                <td className="text-center">
                  {numberFormatter(o.project_equity)}
                </td>
                <td className="text-center">
                  {numberFormatter(o.dollar_per_unit, 0)}
                </td>
                <td className="text-center">{o.project_type}</td>
                <td className="text-center">{o.no_of_buildings}</td>
                {/* <td className="text-start">{`${o.city},${o.state}`}</td> */}
                <td className="text-start">
                  {o.google_pin_link ? (
                    <a
                      href={o.google_pin_link}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {`${o.city},${o.state}`}
                    </a>
                  ) : (
                    <>{`${o.city},${o.state}`}</>
                  )}
                </td>
              </tr>
            ))}
            <tr className="custom-bg-footer">
              <th></th>
              <th>
                <>Portfolio Totals</>
              </th>
              <th>{numberFormatterOnly(totals.units)}</th>
              <th className="text-center">
                {numberFormatter(totals.project_cost)}
              </th>
              <th className="text-center">
                {numberFormatter(totals.project_debt)}
              </th>
              <th className="text-center">
                {numberFormatter(totals.project_equity)}
              </th>
              <th className="text-center">
                {numberFormatter(totals.dollar_per_unit, 0)}
              </th>
              <th className="text-center">Mixed Variety</th>
              <th className="text-center">{totals.buildings}</th>
              <th>Multiple TX</th>
              {/* <th>
                  {deck_link ? (
                    <a href={deck_link} target="_blank">
                      {deck_name}
                    </a>
                  ) : (
                    <>{deck_name}</>
                  )}
                </th> */}
            </tr>
          </tbody>
        </table>
      )}

      <Modal show={show} onHide={() => setShow(false)} size={"lg"}>
        <ModalHeader closeButton>
          <div className="container-fluid">
            <h5 className="mb-0 w-100">{prop?.name}</h5>
            <small className="">{prop?.address}</small>
          </div>
        </ModalHeader>
        <ModalBody>
          <ProjectImagesList docsIds={prop?.property_image} />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Cost</label>
                  <div className="value">
                    {numberFormatter(prop?.project_cost)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Equity</label>
                  <div className="value">
                    {numberFormatter(prop?.project_equity)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Debt</label>
                  <div className="value">
                    {numberFormatter(prop?.project_debt)}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Project Type</label>
                  <div className="value">{prop?.project_type}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Units</label>
                  <div className="value">{prop?.units}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Acreage</label>
                  <div className="value">{prop?.acreage}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Target IRR</label>
                  <div className="value">{prop?.targeted_investor_irr}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-like">
                  <label>Target Equity</label>
                  <div className="value">{prop?.targeted_equity_multiple}</div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ProjectImagesList = ({ docsIds = [] }) => {
  return (
    <Carousel className="mb-3" interval={3000} fade>
      {docsIds.map((obj) => (
        <Carousel.Item>
          <SingleImage docIds={[obj]} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default NewOfferPropertyV1;

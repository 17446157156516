import React from "react";
import PhoneInput from "react-phone-input-2";

import "./style.scss";
import "react-phone-input-2/lib/bootstrap.css";

const InputPhone = ({
  defaultCountry = "us",
  onChange = () => {},
  onBlur = () => {},
  value,
  required = false,
  className = "",
  onEnterKeyPress = () => {},
}) => {
  let classInput = "";
  classInput += className;
  classInput += required ? " border-danger" : "";

  return (
    <>
      <PhoneInput
        onEnterKeyPress={onEnterKeyPress}
        country={defaultCountry}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputClass={classInput}
      />
    </>
  );
};

export default InputPhone;
